import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";

const InviteAcceptance = ({ user }) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const processInviteOrReferral = async () => {
      try {
        const authToken = localStorage.getItem("token");

        // If no token or user, redirect to login
        if (!authToken || !user) {
          if (token) {
            localStorage.setItem("pendingInviteToken", token);
            localStorage.setItem("returnPath", `/invite/${token}`);
          }
          navigate("/");
          return;
        }

        setIsProcessing(true);

        // Add this simple check for self-referral
        if (user.email === token) {
          // If token contains the referral email
          setError("You cannot accept an invite to your own account");
          setIsProcessing(false);
          return;
        }

        // Process invite token if available
        if (token) {
          const inviteResponse = await axios.post(
            `/api/accountant/accept-invite/${token}`,
            {},
            {
              headers: { Authorization: `Bearer ${authToken}` },
            }
          );

          if (inviteResponse.data.success) {
            localStorage.removeItem("pendingInviteToken");
            setIsProcessing(false);
            // Navigate to process-info on success
            navigate("/process-info", {
              state: { message: "Successfully accepted invite" },
            });
          } else {
            throw new Error(
              inviteResponse.data.error || "Invite processing failed"
            );
          }
        }
      } catch (error) {
        console.error("Processing error:", error);
        setIsProcessing(false);
        setError(
          error.response?.data?.error ||
            "An error occurred while processing. Please try again."
        );
      }
    };

    processInviteOrReferral();
  }, [token, navigate, user]);

  if (error) {
    return (
      <div className="container">
        <div
          className="error-message"
          style={{ color: "#dc3545", padding: "20px", textAlign: "center" }}
        >
          <h3>Processing Error</h3>
          <p>{error}</p>
          <button
            onClick={() => navigate("/process-info")}
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              borderRadius: "4px",
              border: "none",
              backgroundColor: "#0033a0",
              color: "white",
              cursor: "pointer",
            }}
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container" style={{ textAlign: "center", padding: "40px" }}>
      {isProcessing ? (
        <>
          <TailSpin color="#0033a0" height={40} width={40} />
          <p>Processing invite...</p>
        </>
      ) : (
        <p>Successfully processed! Redirecting...</p>
      )}
    </div>
  );
};

export default InviteAcceptance;
