import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Correct import
import { TailSpin } from "react-loader-spinner";
import "../styles/PricingPage.css";

export default function PricingPage() {
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      setClientSecret(decoded.stripeClientSecret);
      setUserEmail(decoded.email); // Get email from JWT
    }
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  // Only show loading if we don't have either clientSecret or userEmail
  if (!clientSecret && !userEmail) {
    return (
      <div className="pricing-page-container">
        <button className="back-button" onClick={handleBackClick}>
          &larr; Back
        </button>
        <div className="loading-container">
          <TailSpin color="#0033a0" height={50} width={50} />
          <p>Loading pricing options...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pricing-page-container">
      <button className="back-button" onClick={handleBackClick}>
        &larr; Back
      </button>
      <h1 className="pricing-page-title">Choose Your Plan</h1>
      <stripe-pricing-table
        pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        {...(clientSecret
          ? { "customer-session-client-secret": clientSecret }
          : { "customer-email": userEmail })}
      />
    </div>
  );
}
