import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Add this import
import "../styles/CheckoutButton.css";

export default function CheckoutButton({
  updateUI,
  isTrial,
  isSubscribed,
  cancelAt,
  setUser,
  action,
}) {
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const handleReactivation = async () => {
    setLoading(true);
    try {
      const storedToken = localStorage.getItem("token");

      const response = await fetch("/api/create-checkout-session", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to reactivate: ${response.status}`);
      }

      const { newToken } = await response.json();

      localStorage.setItem("token", newToken); // Save the new token
      const decoded = jwtDecode(newToken);

      // Update UI with decoded token
      if (updateUI) {
        updateUI(decoded);
      }
      setUser(decoded); // Update user state like in cancel flow
    } catch (error) {
      console.error("Error during reactivation:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToPricing = async () => {
    setLoading(true);
    try {
      const storedToken = localStorage.getItem("token");

      // Use fetch instead of axios to match your working pattern
      const response = await fetch("/api/get-new-token", {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch new token: ${response.status}`);
      }

      const data = await response.json();
      const newToken = data.token;
      if (!newToken) {
        throw new Error("No new token received from server");
      }

      // Save the new token
      localStorage.setItem("token", newToken);

      // Now navigate
      navigate("/pricing");
    } catch (error) {
      console.error("Error preparing pricing page:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderButtonLabel = () => {
    if (loading) {
      return (
        <div className="button-loading">
          <TailSpin color="#0033a0" height={16} width={16} />
          <span>Loading...</span>
        </div>
      );
    }

    if (action === "upgrade") {
      return "Upgrade Plan";
    }

    if (isTrial) {
      return "Subscribe";
    }

    if (cancelAt) {
      return "Reactivate Plan";
    }

    if (isSubscribed) {
      return "Change Plan";
    }

    return "Subscribe";
  };

  const handleClick = async () => {
    if (cancelAt) {
      handleReactivation();
    } else {
      await handleNavigateToPricing();
    }
  };

  return (
    <button
      className="checkoutbutton logout-link"
      onClick={handleClick}
      disabled={loading} // Disable button when loading
    >
      {renderButtonLabel()}
    </button>
  );
}
