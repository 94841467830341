import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function NoMatch() {
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    console.log("NoMatch rendered for path:", location.pathname);
    // Add a small delay before redirecting to allow for logging
    const timer = setTimeout(() => {
      navigate("/", { replace: true });
    }, 100);

    return () => clearTimeout(timer);
  }, [location.pathname, navigate]);

  // This return can be empty or show a loading state since it will redirect quickly
  return <div>Redirecting...</div>;
}

export default NoMatch;
