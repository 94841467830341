import React, { useState, useEffect } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "../styles/DateRangeSelector.css";

const DateRangeSelector = ({ onClose, onDateRangeSelected }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Adjust breakpoint as needed
    };

    // Set initial state
    handleResize();

    // Add resize listener
    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const predefinedRanges = [
    {
      label: "Last 7 days",
      value: [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(),
      ],
    },
    {
      label: "Last 30 days",
      value: [
        new Date(new Date().setDate(new Date().getDate() - 30)),
        new Date(),
      ],
    },
  ];

  const handleSelection = () => {
    const [startDate, endDate] = dateRange;
    if (startDate && endDate) {
      onDateRangeSelected(startDate.toISOString(), endDate.toISOString());
    }
    onClose();
  };

  const disableFutureAndLimitPastDates = (date) => {
    const today = new Date();
    const pastLimit = new Date();
    pastLimit.setDate(today.getDate() - 30); // Limit to the past 30 days
    return date > today || date < pastLimit;
  };

  return (
    <>
      <div className="modal-backdrop"></div>
      <div className="date-range-selector">
        <h2>Select Date Range</h2>
        <DateRangePicker
          value={dateRange}
          onChange={(value) => setDateRange(value)}
          ranges={predefinedRanges}
          appearance="subtle"
          placeholder="Select Date Range"
          disabledDate={disableFutureAndLimitPastDates}
          format="yyyy-MM-dd"
          oneTap={false}
          showOneCalendar={isMobile}
          cleanable={false} // Disable the "X" button
        />
        <button onClick={handleSelection}>Confirm Selection</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </>
  );
};

export default DateRangeSelector;
