import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/faq.css";

const FAQPage = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("quickstart");

  const handleBackClick = () => {
    navigate("/");
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setActiveSection(sectionId);
  };

  return (
    <div className="faq-container">
      <header className="faq-header">
        <button className="back-button" onClick={handleBackClick}>
          ← Back
        </button>
        <div className="logo-container">
          <a href="/">
            <img
              src="./eblogo_2.png"
              alt="ExpenseBot Logo"
              className="faq-logo"
            />
          </a>
        </div>
        <div className="faq-title-container">
          <h1 className="faq-title">ExpenseBot Guide & FAQ</h1>
        </div>
      </header>

      {/* Navigation Menu */}
      <nav className="faq-nav">
        <button
          className={`nav-item ${
            activeSection === "quickstart" ? "active" : ""
          }`}
          onClick={() => scrollToSection("quickstart")}
        >
          Quick Start
        </button>
        <button
          className={`nav-item ${activeSection === "setup" ? "active" : ""}`}
          onClick={() => scrollToSection("setup")}
        >
          Complete Setup
        </button>
        <button
          className={`nav-item ${activeSection === "faq" ? "active" : ""}`}
          onClick={() => scrollToSection("faq")}
        >
          FAQ
        </button>
        <button
          className={`nav-item ${activeSection === "tips" ? "active" : ""}`}
          onClick={() => scrollToSection("tips")}
        >
          Tips & Best Practices
        </button>
      </nav>

      {/* Quick Start Guide Section */}
      <section id="quickstart" className="section">
        <h2 className="section-header">Quick Start Guide</h2>

        <div className="subsection">
          <h3 className="subsection-title">Getting Started</h3>
          <div className="step-list">
            <div className="step-item">
              <span className="step-number">1</span>
              <div className="step-content">
                Click "Get Photos from Google Photos" and select a few images of
                receipts. TIP : When selecting photos choose the search icon and
                type in "Receipts" and it will show all your photos of receipts.
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">2</span>
              <div className="step-content">
                Wait for processing completion (you'll receive an email)
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">3</span>
              <div className="step-content">
                Click the "View Expense Spreadsheet" button to see them or click
                on the link in the email.
              </div>
            </div>
          </div>

          <div className="important-note">
            Important: Add info@expensebot.ai to your safe sender list to
            receive processing notifications
          </div>
        </div>
      </section>

      {/* Complete Setup Guide Section */}
      <section id="setup" className="section">
        <h2 className="section-header">Complete Setup Guide</h2>

        {/* Settings Configuration */}
        <div className="subsection">
          <h3 className="subsection-title">
            Spreadsheet: Settings Tab Configuration
          </h3>
          <p>Navigate to the Settings tab on your spreadsheet to customize:</p>

          <div className="step-list">
            <div className="step-item">
              <span className="step-number">1</span>
              <div className="step-content">
                <strong>Submission Emails (Optional) </strong>
                <p>
                  Enter email addresses of people who should receive your
                  submitted reports (EA, Accountant, Reviewers)
                </p>
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">2</span>
              <div className="step-content">
                <strong>Employee ID (Optional)</strong>
                <p>Add if your company requires it for expense submissions</p>
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">3</span>
              <div className="step-content">
                <strong>Home Currency</strong>
                <p>
                  Choose/Edit your local currency from the drop down (the
                  currency you use to pay your credit card bills)
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Expense Accounts Configuration */}
        <div className="subsection">
          <h3 className="subsection-title">
            Spreadsheet: Expense Accounts Setup
          </h3>
          <p>
            Navigate to the Expense Accounts tab on your spreadsheet to
            customize:
          </p>

          <div className="step-list">
            <div className="step-item">
              <span className="step-number">1</span>
              <div className="step-content">
                <strong>Expense Accounts (Column A)</strong>
                <p>
                  Modify these to match the expense categories for your
                  organization
                </p>
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">2</span>
              <div className="step-content">
                <strong>Clues for AI (Column B)</strong>
                <p>
                  Describe what types of expenses belong in each account so the
                  AI can better classify
                </p>
              </div>
            </div>
          </div>

          <div className="important-note">
            Example: For expense account "1-1234", you might write "All meals
            and entertainment expenses related to restaurants, bars, or
            entertainment venues". Or simply have "Meals and Entertainment" as
            the expense account name.
          </div>
        </div>

        {/* Tags Configuration */}
        <div className="subsection">
          <h3 className="subsection-title">Spreadsheet: Tags Setup</h3>
          <p>
            Navigate to the Tags tab on your spreadsheet to organize receipts by
            client, project, or trip:
          </p>

          <div className="step-list">
            <div className="step-item">
              <span className="step-number">1</span>
              <div className="step-content">
                <strong>Tag Column (Column A)</strong>
                <p>
                  Default tags include Business, Property, Personal. Replace
                  these, and add new ones for:
                </p>
                <ul>
                  <li>Business Trips (e.g., "Las Vegas")</li>
                  <li>Clients (e.g., "Westjet")</li>
                  <li>Projects (e.g., "Building Expansion")</li>
                </ul>
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">2</span>
              <div className="step-content">
                <strong>Clues for AI (Column B)</strong>
                <p>Add descriptions to help auto-categorization:</p>
                <ul>
                  <li>
                    Trip Example: "All receipts with city Las Vegas or State,
                    Nevada"
                  </li>
                  <li>
                    Client Example: "All receipts that have the word Westjet"
                  </li>
                  <li>
                    Project Example: "All receipts from merchants like hardware
                    stores"
                  </li>
                </ul>
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">3</span>
              <div className="step-content">
                <strong>Reimburse Column (Column C)</strong>
                <p>Mark each tag with:</p>
                <ul>
                  <li>YES: Include in expense report submission</li>
                  <li>NO: Exclude from expense report submission</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="important-note">
            Handwrite the Tag name on your receipt and it will auto-categorize
            it into the correct group.
          </div>
        </div>

        {/* Report Submission Process */}
        <div className="subsection">
          <h3 className="subsection-title">Submitting Reports</h3>
          <div className="step-list">
            <div className="step-item">
              <span className="step-number">1</span>
              <div className="step-content">
                Go to the <strong>Reports</strong> menu on ExpenseBot.ai then
                click "Preview Submission" to preview the expense report you
                want to create.
                <i
                  className="fas fa-info-circle tip-icon"
                  aria-hidden="true"
                ></i>
                Previously submitted receipts will be omitted.
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">2</span>
              <div className="step-content">
                Confirm using the preview summary:
                <ul>
                  <li>Expense totals by Currency</li>
                  <li>Expense totals for certain Tags</li>
                  <li>
                    The Functional Total which is the sum of the Func. Total
                    column on the main spreadsheet.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">3</span>
              <div className="step-content">
                Click Submit to create the report and email to the recipients
                you configured on the Settings tab in the main spreadsheet.
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">4</span>
              <div className="step-content">
                If you want to share your expense reports with someone send them
                this url https://www.expensebot.ai/reviewer and they'll be able
                to invite you to see your reports.
              </div>
            </div>
          </div>

          <div className="important-note">
            Func. Total: If you have expenses in other currencies, wait for your
            credit card statement and add the converted total under Func. Total
            on your main spreadsheet.
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="section">
        <h2 className="section-header">Frequently Asked Questions</h2>

        {/* General Questions */}
        <div className="subsection">
          <h3 className="subsection-title">General Questions</h3>

          <div className="faq-item">
            <p className="faq-question">What is ExpenseBot?</p>
            <p className="faq-answer">
              ExpenseBot is an AI-powered service that helps you save hours
              every week, and get paid faster. ExpenseBot will scan photos in
              your Google Photos, organize them into a Google Sheets
              spreadsheet, and let you submit them by client, by trip, by
              project, or organized however you like. ExpenseBot empowers you to
              get paid faster, and enables your EA or accounting to save many
              hours per week.
            </p>
          </div>

          <div className="faq-item">
            <p className="faq-question">Do I need an app?</p>
            <p className="faq-answer">
              No, just sign in at https://www.expensebot.ai
            </p>
          </div>
        </div>

        {/* Technical Requirements */}
        <div className="subsection">
          <h3 className="subsection-title">Technical Requirements</h3>

          <div className="faq-item">
            <p className="faq-question">
              Google Photos App for either iPhone or Android
            </p>
            <p className="faq-answer">
              ExpenseBot works exclusively with Google Photos. You must have
              automatic photo syncing enabled on your device. Setup guides:
              <a
                href="https://support.google.com/photos/answer/6193313?co=GENIE.Platform%3DiOS&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                iPhone
              </a>{" "}
              and
              <a
                href="https://support.google.com/photos/answer/6193313?co=GENIE.Platform%3DAndroid&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Android
              </a>
              .
            </p>
          </div>
        </div>

        {/* Privacy & Security */}
        <div className="subsection">
          <h3 className="subsection-title">Privacy & Security</h3>

          <div className="faq-item">
            <p className="faq-question">How does ExpenseBot handle my data?</p>
            <p className="faq-answer">
              ExpenseBot uses a <strong>privacy-first</strong> approach. Unlike
              most apps, ExpenseBot does not store your data. All data (photos
              and spreadsheets) is stored on your own Google Drive, accessible
              only by you.
            </p>
          </div>

          <div className="faq-item">
            <p className="faq-question">
              Can I reset my account or delete my data?
            </p>
            <p className="faq-answer">
              Yes, under <strong>Settings</strong>, choose{" "}
              <strong>Reset Account</strong> to delete the ExpenseBot folder on
              your Drive and start fresh. This won't affect your subscription
              status.
            </p>
          </div>
        </div>
      </section>

      {/* Tips & Best Practices Section */}
      <section id="tips" className="section">
        <h2 className="section-header">Tips & Best Practices</h2>

        <div className="tips-container">
          <div className="tip-card">
            <h3 className="tip-title">Receipt Photos</h3>
            <ul className="tips-list">
              <li>
                <i className="fas fa-camera tip-icon"></i>
                Take clear, well-lit photos
              </li>
              <li>
                <i className="fas fa-receipt tip-icon"></i>
                For restaurants receipts, in the same photo include both the
                itemized receipt and the credit card slip so ExpenseBot can
                capture tax, tip and total
              </li>
              <li>
                <i className="fas fa-pen tip-icon"></i>
                Write Tag names directly on receipts before photographing so it
                can be auto-classified
              </li>
              <li>
                <i className="fas fa-mobile-alt tip-icon"></i>
                Take screenshots of email receipts
              </li>
            </ul>
          </div>

          <div className="tip-card">
            <h3 className="tip-title">Currency Management</h3>
            <ul className="tips-list">
              <li>
                <i className="fas fa-clock tip-icon"></i>
                Wait for credit card statements before submitting foreign
                currency expenses and add converted amounts in the Func. Total
                column of your main spreadsheet
              </li>
              <li>
                <i className="fas fa-plane tip-icon"></i>
                Create a new tag under the Tags tab of your spreadsheet to group
                together a trip to another country. Many people create a tag
                with a specific trip name, e.g., "Paris Conf."
              </li>
              <li>
                <i className="fas fa-toggle-on tip-icon"></i>
                Before submitting an expense report, in the Tags tab of your
                spreadsheet, set the Tag for that trip under the Reimburse
                column to "NO" to exclude these expenses from the report until
                you receive your credit card statement. After entering the
                converted amounts in the Func. Totals column, update the Tag for
                that trip to "YES" to enable submission for those expenses.
              </li>
            </ul>
          </div>

          <div className="tip-card">
            <h3 className="tip-title">Organization Tips</h3>
            <ul className="tips-list">
              <li>
                <i className="fas fa-tags tip-icon"></i>
                Create tags under the TAGS tab in your spreadsheet for different
                projects or trips. Enter YES or NO in the Reimburse column to
                identify which expenses you want to include in your next report
                submission.
              </li>
              <li>
                <i className="fas fa-highlighter tip-icon"></i>
                Review any yellow highlighted cells to review AI's suggestions
              </li>
              <li>
                <i className="fas fa-cog tip-icon"></i>
                Customize AI classification rules in the spreadsheet under the
                Expense Account or Tags tabs
              </li>
              <li>
                <i className="fas fa-undo-alt tip-icon"></i>
                To resubmit expenses in a new report, remove the 'Submit Date'
                and 'Batch ID' entries from the main spreadsheet and they will
                resubmit in the next report you create.{" "}
              </li>
            </ul>
          </div>
        </div>

        {/* For EAs and Accountants */}
        <div className="subsection">
          <h3 className="subsection-title">For EAs and Accountants</h3>
          <div className="step-list">
            <div className="step-item">
              <span className="step-number">1</span>
              <div className="step-content">
                Go to https://www.expensebot.ai/reviewer
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">2</span>
              <div className="step-content">
                Invite users to manage their expense reports
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">3</span>
              <div className="step-content">
                When they accept you will be able to see and edit their expense
                reports, set status and more.
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">4</span>
              <div className="step-content">
                For QuickBooks users: Review, modify, and submit directly to
                QuickBooks
              </div>
            </div>
          </div>
        </div>

        <div className="important-note">
          Need help? Email support@expensebot.ai
        </div>
      </section>
    </div>
  );
};

export default FAQPage;
