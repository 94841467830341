import React, { useState, useEffect } from "react";
import "../styles/PhotoSelector.css";

const ICloudPhotoSelector = ({ onClose, onPhotosSelected, onError }) => {
  const [status, setStatus] = useState("initial");
  const [selectedCount, setSelectedCount] = useState(0);
  const PHOTO_LIMIT = 100;

  useEffect(() => {
    const handleAppClipResponse = (event) => {
      let data;
      // Handle both postMessage and URL params
      if (event.type === "message") {
        data = event.data;
      } else {
        const params = new URLSearchParams(window.location.search);
        if (params.has("status")) {
          data = {
            type: "APP_CLIP_STATUS",
            status: params.get("status"),
            count: parseInt(params.get("count") || "0"),
          };
        } else if (params.has("photos")) {
          try {
            data = {
              type: "APP_CLIP_PHOTOS",
              photos: JSON.parse(decodeURIComponent(params.get("photos"))),
            };
          } catch (error) {
            console.error("Error parsing photo data:", error);
            onError("Failed to process selected photos");
            return;
          }
        }
      }

      if (!data) return;

      switch (data.type) {
        case "APP_CLIP_STATUS":
          setStatus(data.status);
          if (data.count) setSelectedCount(data.count);
          break;

        case "APP_CLIP_PHOTOS":
          const photos = data.photos;
          if (photos.length > PHOTO_LIMIT) {
            onError(`Maximum ${PHOTO_LIMIT} photos allowed`);
            return;
          }

          const transformedPhotos = photos.map((photo) => ({
            id: photo.id,
            baseUrl: photo.url,
            mimeType: photo.mimeType,
            filename: photo.filename,
            mediaMetadata: {
              creationTime: photo.createdAt,
            },
            type: photo.type || "PHOTO",
          }));

          setStatus("processing");
          onPhotosSelected(transformedPhotos);
          onClose();
          break;

        case "APP_CLIP_ERROR":
          onError(data.message);
          onClose();
          break;

        default:
          console.warn("Received unknown App Clip response type:", data.type);
          break;
      }
    };

    window.addEventListener("message", handleAppClipResponse);
    return () => window.removeEventListener("message", handleAppClipResponse);
  }, [onPhotosSelected, onClose, onError]);

  const launchAppClip = () => {
    const currentUrl = window.location.href;
    window.location.href = `expensebot://select-photos?returnUrl=${encodeURIComponent(
      currentUrl
    )}&limit=${PHOTO_LIMIT}`;
    setStatus("launching");
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content photo-selector">
        <button onClick={onClose} className="close-x">
          ×
        </button>

        {status === "initial" && (
          <div className="picker-container">
            <div className="photo-limit-info">
              <p>Select up to {PHOTO_LIMIT} photos from your iPhone</p>
            </div>

            <div className="picker-content">
              <div className="web-picker">
                <h3>Import from iPhone Photos</h3>
                <p className="mode-description">
                  Securely select and import receipt photos directly from your
                  iPhone's photo library
                </p>
                <div className="photo-source-buttons">
                  <button onClick={launchAppClip} className="primary-button">
                    Open Photo Selector
                  </button>
                </div>
                <div className="picker-info">
                  <p>
                    Your photos will be processed securely on your device before
                    being uploaded
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {status !== "initial" && (
          <div className="status-container">
            {status === "launching" && (
              <div className="waiting-status">
                <i className="fas fa-rocket"></i>
                <div className="status-message">
                  <strong>Opening photo selector...</strong>
                  <p>
                    The photo selector should open automatically on your iPhone
                  </p>
                </div>
              </div>
            )}

            {status === "selecting" && (
              <div className="waiting-status">
                <i className="fas fa-images"></i>
                <div className="status-message">
                  <strong>
                    {selectedCount > 0
                      ? `${selectedCount} photos selected...`
                      : "Select your photos"}
                  </strong>
                  <p>Choose the receipt photos you want to process</p>
                </div>
              </div>
            )}

            {status === "processing" && (
              <div className="processing-status">
                <i className="fas fa-spinner fa-spin"></i>
                <div className="status-message">
                  <strong>Processing {selectedCount} photos...</strong>
                  <p>Your photos are being prepared for upload</p>
                </div>
              </div>
            )}

            {status === "sending" && (
              <div className="processing-status">
                <i className="fas fa-paper-plane"></i>
                <div className="status-message">
                  <strong>Finalizing selection...</strong>
                  <p>Almost done!</p>
                </div>
              </div>
            )}

            {status !== "processing" && status !== "sending" && (
              <button onClick={onClose} className="cancel-button">
                Cancel Selection
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ICloudPhotoSelector;
