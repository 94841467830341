import { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";

const ShareToQuickBooks = ({
  reportId,
  quickbooks,
  isConnected: initialConnectionStatus,
  onStatusUpdate,
  onConnectionStatusChange,
  isClientOfAccountant,
  clientEmail, // Add this prop for accountant view
}) => {
  const [isSharing, setIsSharing] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(initialConnectionStatus);

  useEffect(() => {
    setIsConnected(initialConnectionStatus);
  }, [initialConnectionStatus]);

  const handleConnectionChange = (newStatus) => {
    setIsConnected(newStatus);
    onConnectionStatusChange?.(newStatus);
  };

  const handleShare = async () => {
    if (!isConnected) {
      setError("QuickBooks is not connected. Please connect first.");
      return;
    }

    setIsSharing(true);
    setError(null);

    try {
      const response = await fetch("/api/quickbooks/share-report", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          reportId,
          clientEmail: isClientOfAccountant ? clientEmail : undefined, // Add clientEmail if accountant
        }),
      });

      const data = await response.json();

      if (response.status === 401 && data.reauthorizationUrl) {
        window.location.href = data.reauthorizationUrl;
        return;
      }

      if (!response.ok) {
        if (
          data.error?.includes("reconnect") ||
          data.error?.includes("expired") ||
          data.error?.includes("authorization")
        ) {
          setIsConnected(false);
          throw new Error(
            "QuickBooks connection expired. Please reconnect to continue."
          );
        }
        throw new Error(data.error || "Failed to share report");
      }

      if (data.success && data.transactionId) {
        onStatusUpdate &&
          onStatusUpdate({
            status: "submitted",
            submittedAt: new Date().toISOString(),
            transactionId: data.transactionId,
            error: null,
          });
      }
    } catch (error) {
      setError(error.message);
      if (
        error.message?.includes("reconnect") ||
        error.message?.includes("expired") ||
        error.message?.includes("authorization")
      ) {
        setIsConnected(false);
      }

      onStatusUpdate &&
        onStatusUpdate({
          status: "failed",
          error: error.message,
          submittedAt: new Date().toISOString(),
        });
    } finally {
      setIsSharing(false);
    }
  };

  const handleConnect = async () => {
    setIsConnecting(true);
    try {
      const currentPath = window.location.pathname + window.location.search;
      localStorage.setItem("qbReturnPath", currentPath);
      localStorage.setItem("selectedMenu", "expenseReports");

      // Create URL with query parameters if needed
      const url = isClientOfAccountant
        ? `/api/quickbooks/connect?clientEmail=${encodeURIComponent(
            clientEmail
          )}`
        : "/api/quickbooks/connect";

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to initiate connection");
      }

      const data = await response.json();
      if (data.authUri) {
        window.location.href = data.authUri;
      } else {
        throw new Error("No authorization URI received");
      }
    } catch (err) {
      console.error("Connection error:", err);
      setError("Failed to initiate QuickBooks connection");
      handleConnectionChange(false);
    } finally {
      setIsConnecting(false);
    }
  };

  // Remove the isClientOfAccountant check since we're treating it as normal flow
  if (quickbooks?.status === "submitted") {
    return (
      <div className="submitted-status">
        <span className="text-green-500">✓ Submitted to QuickBooks</span>
      </div>
    );
  }

  if (!isConnected) {
    return (
      <div className="space-y-4">
        <button
          onClick={handleConnect}
          disabled={isConnecting}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-gray-400"
        >
          {isConnecting ? (
            <div className="flex items-center justify-center">
              <TailSpin color="#ffffff" height={20} width={20} />
              <span className="ml-2">Connecting...</span>
            </div>
          ) : (
            "Connect QuickBooks"
          )}
        </button>
        {error && <p className="text-red-500">{error}</p>}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <button
        onClick={handleShare}
        disabled={isSharing}
        className={`${
          isSharing ? "bg-gray-400" : "bg-green-500 hover:bg-green-600"
        } text-white px-4 py-2 rounded transition-colors disabled:bg-gray-400`}
      >
        {isSharing ? (
          <div className="flex items-center justify-center">
            <TailSpin color="#ffffff" height={20} width={20} />
            <span className="ml-2">Sharing...</span>
          </div>
        ) : (
          "Share to QuickBooks"
        )}
      </button>
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default ShareToQuickBooks;
