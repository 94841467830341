import React, { useEffect, useState, useCallback } from "react";
import { isTokenExpired, refreshToken } from "./components/utils/tokenUtils";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import Home from "./components/Home";
import ProcessInfo from "./components/ProcessInfo";
import NoMatch from "./components/NoMatch";
import FAQPage from "./components/faq";
import TERMSPage from "./components/terms";
import PRIVACYPage from "./components/privacy";
import InviteAcceptance from "./components/InviteAcceptance";
import AccountantView from "./components/AccountantView";
import FirstTimeUser from "./components/FirstTimeUser";
import PricingPage from "./components/PricingPage";
import Viewers from "./components/ViewerList";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [processInfo, setProcessInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  /* const Cancel = () => {
    const navigate = useNavigate();

    useEffect(() => {
      navigate("/process-info");
    }, [navigate]);

    return null;
  }; */

  const Dashboard = () => {
    const navigate = useNavigate();

    useEffect(() => {
      navigate("/process-info");
    }, [navigate]);

    return null;
  };

  const fetchSessionInfo = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("No token found in localStorage");
      setLoading(false);
      return;
    }

    try {
      console.log("Fetching session info with token:", token);
      const response = await axios.get("/api/session-info", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      // If accountant role show AdminView
      localStorage.setItem(
        "adminViewEnabled",
        data.roles?.accountant?.toString() || "false"
      );
      setLoggedIn(true);
      setUser(data);
      setProcessInfo(data.processInfo);
    } catch (error) {
      console.error("Error fetching session info:", error);
      localStorage.removeItem("token");
      localStorage.removeItem("adminViewEnabled");

      setLoggedIn(false);
      setUser(null);
      setProcessInfo(null);
    } finally {
      setLoading(false);
    }
  }, []);

  // Now define handleTokenRefresh since it depends on fetchSessionInfo
  const handleTokenRefresh = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (token && isTokenExpired(token)) {
        await refreshToken();
        await fetchSessionInfo();
      }
    } catch (error) {
      console.error("Error handling token refresh:", error);
      localStorage.removeItem("token");
      setLoggedIn(false);
      setUser(null);
      setProcessInfo(null);
      navigate("/");
    }
  }, [fetchSessionInfo, navigate]);

  useEffect(() => {
    handleTokenRefresh();

    // Check token every 5 minutes
    const intervalId = setInterval(handleTokenRefresh, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [handleTokenRefresh]);

  // Setup the Axios response interceptor inside useEffect to ensure it is only set once
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        const newToken = response.headers["x-new-jwt"];
        if (newToken) {
          console.log("New token received from middleware:", newToken);
          localStorage.setItem("token", newToken);
        }
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        // If the error is 401 and we haven't tried to refresh yet
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            await handleTokenRefresh();
            // Retry the original request with the new token
            const token = localStorage.getItem("token");
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          } catch (refreshError) {
            // If refresh fails, redirect to login
            localStorage.removeItem("token");
            setLoggedIn(false);
            setUser(null);
            setProcessInfo(null);
            navigate("/");
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [handleTokenRefresh, navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const success = urlParams.get("success");
    const page = urlParams.get("page");
    const googleCode = urlParams.get("code");
    const referralEmail = urlParams.get("referral");
    const inviteToken = urlParams.get("invite"); // Add this line

    if (referralEmail) {
      localStorage.setItem("referralEmail", referralEmail);
    }

    if (inviteToken) {
      localStorage.setItem("pendingInviteToken", inviteToken);
    }

    // Handle Google auth and other cases
    if (googleCode) {
      console.log("Making Google API call with code:", googleCode);
      axios
        .get(`/api/oauth2callback?code=${googleCode}`)
        .then((response) => {
          console.log("Received response:", response.data);
          const { token } = response.data;
          localStorage.setItem("token", token);
          localStorage.setItem("auth_provider", "google");
          console.log("token set in app.js: ", token);
          return fetchSessionInfo();
        })
        .then(() => {
          // Now we have user info, check for pending invite
          const pendingInviteToken = localStorage.getItem("pendingInviteToken");
          const returnPath = localStorage.getItem("returnPath");

          if (pendingInviteToken && returnPath) {
            console.log("Found pending invite, navigating to:", returnPath);
            // Don't remove tokens yet - InviteAcceptance component will handle cleanup
            navigate(returnPath);
          } else {
            const selectedMenu = localStorage.getItem("selectedMenu") || page;
            navigate("/process-info", { state: { returnPage: selectedMenu } });
          }

          window.history.replaceState({}, document.title, "/");
        })
        .catch((error) => {
          console.error("Error handling Google OAuth callback:", error);
          if (error.response && error.response.status === 403) {
            alert(
              "It looks like some permissions were not granted. Please log in again and grant all required permissions."
            );
          }
          navigate("/");
        });
    } else if (success) {
      console.log("Inside app.js handling success=true");
      const token = localStorage.getItem("token");

      if (token) {
        console.log("in token app.js");
        axios
          .get(`/api/get-new-token`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const { token: newToken } = response.data;
            console.log("new token: ", newToken);
            localStorage.setItem("token", newToken);
            fetchSessionInfo();
            navigate("/process-info");
          })
          .catch((error) => {
            console.error("Error fetching new token:", error);
            navigate("/");
          });
      } else {
        console.error("No token found in localStorage for fetching new token");
        navigate("/");
      }
    } else {
      fetchSessionInfo();
    }
  }, [fetchSessionInfo, location.pathname, location.search, navigate]);

  useEffect(() => {
    if (!loading) {
      // Don't redirect on public routes and success
      if (
        location.pathname === "/success" ||
        location.pathname === "/faq" ||
        location.pathname === "/privacy" ||
        location.pathname === "/terms"
      ) {
        return;
      }

      // If we're on process-info and logged in, don't redirect
      if (location.pathname === "/process-info" && loggedIn) {
        return;
      }

      // Handle other cases
      if (!loggedIn && location.pathname !== "/") {
        navigate("/", { replace: true });
      } else if (
        loggedIn &&
        location.pathname === "/" &&
        processInfo?.spreadsheetId
      ) {
        navigate("/process-info", { replace: true });
      }
    }
  }, [loading, loggedIn, location.pathname, navigate, processInfo]);

  useEffect(() => {
    const processReferral = async () => {
      const referralEmail = localStorage.getItem("referralEmail");
      // Clear referral immediately to prevent any possibility of re-processing
      localStorage.removeItem("referralEmail");

      if (referralEmail && loggedIn) {
        try {
          const response = await axios.post(
            "/api/accountant/invite-client",
            {
              clientEmail: referralEmail,
              clientName: "Referred Client",
              fromReferral: true,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.data.success) {
            console.log("Referral email processed successfully");
          } else {
            console.error(
              "Failed to process referral email:",
              response.data.error
            );
          }
        } catch (error) {
          console.error("Error processing referral email:", error);
        }
      }
    };

    if (loggedIn) {
      processReferral();
    }
  }, [loggedIn]);

  //  useEffect to handle Apple auth messages
  useEffect(() => {
    const handleAppleAuth = (event) => {
      if (event.data?.type === "APPLE_AUTH_SUCCESS") {
        console.log("Received Apple auth success:", event.data);
        const { token } = event.data;
        localStorage.setItem("token", token);
        localStorage.setItem("auth_provider", "apple");

        // Call fetchSessionInfo and wait for the user information to be set
        fetchSessionInfo().then(() => {
          setLoggedIn(true);
          const selectedMenu = localStorage.getItem("selectedMenu");
          navigate("/process-info", {
            state: { returnPage: selectedMenu },
            replace: true,
          });
          window.history.replaceState({}, document.title, "/");
        });
      }
    };

    window.addEventListener("message", handleAppleAuth);
    return () => window.removeEventListener("message", handleAppleAuth);
  }, [fetchSessionInfo, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const ClientProcessInfo = () => {
    const { clientEmail } = useParams();
    return <AccountantView clientEmail={clientEmail} />;
  };

  return (
    <Routes>
      <Route path="/" element={<Home loggedIn={loggedIn} user={user} />} />
      <Route
        path="/signup"
        element={
          loggedIn ? (
            <InviteAcceptance user={user} setProcessInfo={setProcessInfo} />
          ) : (
            <Home loggedIn={loggedIn} user={user} />
          )
        }
      />
      <Route
        path="/invite/:token"
        element={
          loggedIn ? (
            <InviteAcceptance user={user} setProcessInfo={setProcessInfo} />
          ) : (
            <Home loggedIn={loggedIn} user={user} />
          )
        }
      />
      {loggedIn && (
        <>
          <Route
            path="/process-info"
            element={
              !processInfo?.spreadsheetId ? (
                <FirstTimeUser user={user} setProcessInfo={setProcessInfo} />
              ) : (
                <ProcessInfo
                  user={user}
                  setUser={setUser}
                  processInfo={processInfo}
                  setProcessInfo={setProcessInfo}
                />
              )
            }
          />
          <Route
            path="/client/:clientEmail/reports"
            element={<ClientProcessInfo />}
          />
          <Route
            path="/reviewer"
            element={
              <Navigate
                to="/process-info"
                state={{ returnPage: "adminView" }}
                replace
              />
            }
          />

          <Route path="/cancel" element={<Navigate to="/" replace />} />
          <Route path="/success" element={<Navigate to="/" replace />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/viewers" element={<Viewers />} />
        </>
      )}
      {/* Keep all your static routes */}
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/terms" element={<TERMSPage />} />
      <Route path="/privacy" element={<PRIVACYPage />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}
export default App;
