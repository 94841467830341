import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useLocation, useParams, Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import PhotoSelector from "./PhotoSelector";
import DateRangeSelector from "./DateRangeSelector";
import "../styles/ProcessInfo.css";
import { jwtDecode } from "jwt-decode";
import CheckoutButton from "./CheckoutButton";
import WelcomeModal from "./WelcomeModal";
import ReportModal from "./ReportModal";
import ShareToQuickBooks from "./ShareToQuickBooks";
import ICloudPhotoSelector from "./iCloudPhotoSelector";
import AdminView from "./AdminView";
import LicenseManager from "./LicenseManager";

const SHOW_ICLOUD_BUTTON = false;

const ProcessInfo = ({ user, setUser, processInfo, setProcessInfo }) => {
  const location = useLocation();
  const [photoSource, setPhotoSource] = useState(null);
  const pollIntervalRef = useRef(null);
  const [quickBooksInitialized, setQuickBooksInitialized] = useState(false);

  const [selectedMenu, setSelectedMenu] = useState("allExpenses");
  const [processing, setProcessing] = useState(
    !processInfo?.processingCompleted
  );
  const [lastSynced, setLastSynced] = useState(processInfo?.endDate || "");
  const [autoSyncEnabled, setAutoSyncEnabled] = useState(
    processInfo?.automaticSyncEnabled
  );
  const [nextSyncMessage, setNextSyncMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [noOutstandingExpenses, setNoOutstandingExpenses] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // Modify existing loading state
  const [loading, setLoading] = useState({
    reviewerToggle: false,
    reports: false, // Add this new loading state
  });

  const [isModalOpen, setIsModalOpen] = useState(false); // Controls modal visibility
  const [formattedReportData, setFormattedReportData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [quickbooksConnectionStatus, setQuickbooksConnectionStatus] = useState(
    processInfo?.quickbooks?.connected || false
  );

  const params = useParams(); // Add this to get client email from route
  const isAccountantView = location.state?.isAccountantView;
  const clientEmail = params?.clientEmail;
  const [adminViewEnabled, setAdminViewEnabled] = useState(false);
  const [editingReportId, setEditingReportId] = useState(null);
  const [editedNote, setEditedNote] = useState("");
  const [reportFilter, setReportFilter] = useState("All");
  const [reportFunctionalTotal, setReportFunctionalTotal] = useState(0);
  const [hasEmptyColumnR, setHasEmptyColumnR] = useState(0);

  const [reportHomeCurrency, setReportHomeCurrency] = useState(null);

  useEffect(() => {
    // Initialize the admin view state from localStorage (default to false if not set)
    const storedAdminView = localStorage.getItem("adminViewEnabled") === "true";
    setAdminViewEnabled(storedAdminView);
  }, []);

  // Toggle Admin View function
  /*  const toggleAdminView = () => {
    setAdminViewEnabled((prev) => {
      const newValue = !prev;
      localStorage.setItem("adminViewEnabled", newValue);
      return newValue;
    });
  }; */

  const fetchReports = useCallback(
    async (pageNum = 1) => {
      try {
        setLoading((prev) => ({ ...prev, reports: true })); // Start spinner
        const response = await axios.get(
          `/api/reports?page=${pageNum}&filter=${reportFilter}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setProcessInfo((prev) => ({
          ...prev,
          reports: response.data.data.reports,
        }));
        setTotalPages(response.data.data.pagination.totalPages);
      } catch (error) {
        console.error("Error fetching reports:", error);
      } finally {
        setLoading((prev) => ({ ...prev, reports: false })); // Stop spinner
      }
    },
    [reportFilter, setProcessInfo]
  );

  const handleReviewerToggle = useCallback(async () => {
    setLoading((prev) => ({ ...prev, reviewerToggle: true }));
    try {
      const response = await fetch("/api/toggle-accountant", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          enabled: !user?.roles?.accountant,
        }),
      });

      if (!response.ok) throw new Error("Failed to toggle reviewer role");

      const data = await response.json();
      if (data.success) {
        setUser((prev) => ({
          ...prev,
          roles: data.roles,
        }));
      }
    } catch (error) {
      console.error("Error toggling reviewer role:", error);
    } finally {
      setLoading((prev) => ({ ...prev, reviewerToggle: false }));
    }
  }, [user?.roles?.accountant, setUser, setLoading]);

  /*  useEffect(() => {
    fetchReports(page);
  }, [page, reportFilter, fetchReports]);
*/
  useEffect(() => {
    if (selectedMenu === "expenseReports") {
      fetchReports(page);
    }
  }, [page, reportFilter, fetchReports, selectedMenu]);

  useEffect(() => {
    // Set appropriate default menu when role changes
    if (user?.roles?.accountant) {
      setSelectedMenu("adminView");
    } else if (user?.roles?.type === "Employer") {
      setSelectedMenu("licenses");
    } else if (user?.roles?.type === "I-Basic") {
      setSelectedMenu("allExpenses");
    } else if (
      user?.roles?.type === "Employee" ||
      user?.roles?.type === "I-Pro" ||
      user?.subscription?.isTrial
    ) {
      setSelectedMenu("allExpenses");
    } else {
      setSelectedMenu("allExpenses"); // default fallback
    }
  }, [user?.roles?.type, user?.roles?.accountant, user?.subscription?.isTrial]);

  useEffect(() => {
    const handleAdminViewReturn = async () => {
      if (location.state?.returnPage === "adminView") {
        setSelectedMenu("adminView");

        if (!user?.roles?.accountant) {
          await handleReviewerToggle();
        }

        if (!adminViewEnabled) {
          setAdminViewEnabled(true);
          localStorage.setItem("adminViewEnabled", "true");
        }

        window.history.replaceState({}, document.title, location.pathname);
      } else if (location.state?.returnPage === "expenseReports") {
        setSelectedMenu("expenseReports");
        window.history.replaceState({}, document.title, location.pathname);
      }
    };

    handleAdminViewReturn();
  }, [
    location.state,
    adminViewEnabled,
    location.pathname,
    user?.roles?.accountant,
    handleReviewerToggle,
  ]);

  const handleMenuClick = (menu) => {
    if (menu === "adminView" && !user?.roles?.accountant) return; // Prevent access if Admin View is not enabled

    setSelectedMenu(menu);
    localStorage.setItem("selectedMenu", menu); // Save to localStorage
  };

  useEffect(() => {
    // Retrieve selectedMenu from localStorage on component mount
    const storedMenu = localStorage.getItem("selectedMenu");
    if (storedMenu) {
      setSelectedMenu(storedMenu);
    }
  }, []);

  const [isPhotoSelectorOpen, setIsPhotoSelectorOpen] = useState(false);

  const [isDateRangeSelectorOpen, setIsDateRangeSelectorOpen] = useState(false);

  const [cancelAt, setCancelAt] = useState(null);

  // For Report management

  const handleNoteUpdate = async (reportId, newNote) => {
    try {
      // Save to database
      const response = await axios.post(
        "/api/update-session",
        {
          reportId,
          notes: newNote,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "Session updated successfully") {
        // Update local state
        setProcessInfo((prev) => ({
          ...prev,
          reports: prev.reports.map((r) =>
            r.reportId === reportId
              ? {
                  ...r,
                  notes: newNote,
                }
              : r
          ),
        }));
        setEditingReportId(null);
      }
    } catch (error) {
      console.error("Failed to save note:", error);
      alert("Failed to save note. Please try again.");
    }
  };

  // Handle status updates
  const handleStatusUpdate = async (reportId, newStatus) => {
    try {
      // Save the updated status to the database
      const response = await axios.post(
        "/api/update-session",
        {
          reportId,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "Session updated successfully") {
        // Update local state
        setProcessInfo((prev) => ({
          ...prev,
          reports: prev.reports.map((r) =>
            r.reportId === reportId
              ? {
                  ...r,
                  status: newStatus,
                }
              : r
          ),
        }));
      }
    } catch (error) {
      console.error("Failed to update status:", error);
      alert("Failed to update status. Please try again.");
    }
  };

  // New state variables for handling submission and displaying messages
  const [submitLoading, setSubmitLoading] = useState(false); // For loading spinner during submission
  const [submitResultMessage, setSubmitResultMessage] = useState(""); // For submission result messages

  // Collapsible states
  const [isAutoPilotOpen, setIsAutoPilotOpen] = useState(false);
  const [isManualSyncOpen, setIsManualSyncOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  /*
  const formatDate = (isoString) => {
    if (!isoString) return "N/A";
    try {
      const date = new Date(isoString);
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
      }).format(date);
    } catch (error) {
      return "Invalid date";
    }
  }; */

  // Cycling through messages while loading

  // const [messageIndex, setMessageIndex] = useState(0);
  /*
  const messages = [
    "<strong> Feel free to close your browser </strong>, we'll email you when we're done.",
    `Scanning <strong> ${processInfo.totalPhotos} photos </strong> from Google Photos for receipts...`,
    "<strong> Write the expense group name on your receipt </strong> to help classify it.",
    `Current status: <strong> ${processInfo.status} </strong>`,
    "<strong> Edit expense groups </strong> on the <strong> Expense Groups tab </strong>.",
    "<strong> Your data is stored on your own Google Drive </strong>. We delete everything immediately after processing.",
    "<strong> Edit  expense categories </strong> on the <strong> Expense Categories tab </strong>.",
    "<strong> Write names of people on your receipt</strong>  and it will appear in <strong> Notes</strong>.",
    `ExpenseBot will check for receipts again on <strong> ${
      processInfo.nextSyncDate
        ? formatDate(
            new Date(processInfo.nextSyncDate).toLocaleString("en-US", {
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Convert to local time zone
            })
          )
        : "N/A"
    } </strong>`,
    "<strong> Subscribe and cancel </strong> whenever you like. <strong> We make it easy for you </strong>.",
    `So far we found <strong> ${processInfo.receiptsCount} receipts.</strong>`,
  ];
*/
  /** 
  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [messages.length]);
   */
  /*  const formatMessage = (message) => {
    if (message.includes("strong")) {
      const parts = message.split(/(<strong>.*?<\/strong>)/);
      return parts.map((part, index) => {
        if (part.startsWith("<strong>") && part.endsWith("</strong>")) {
          return (
            <strong key={index}>{part.replace(/<\/?strong>/g, "")}</strong>
          );
        }
        return part;
      });
    }
    return message;
  }; */

  const toggleSection = (section) => {
    if (section === "autoPilot") setIsAutoPilotOpen(!isAutoPilotOpen);
    if (section === "manualSync") setIsManualSyncOpen(!isManualSyncOpen);
    if (section === "settings") setIsSettingsOpen(!isSettingsOpen);
  };

  const triggerGlobalLoadingState = useCallback((updates = {}) => {
    setProcessing(true);
    setLoading((prevLoading) => ({
      ...prevLoading,
      photoSelector: true,
      dateRangeSelector: true,
      updateSync: true,
      ...updates,
    }));
  }, []);

  const updateSubscriptionStateFromToken = (decoded) => {
    console.log("Decoded token:", decoded); // Log decoded token to ensure it's correct

    setIsSubscribed(decoded.isSubscribed);
    setIsTrial(decoded.isTrial);
    setSubscriptionEndDate(decoded.currentPeriodEnd);
    setCancelAt(decoded.cancelAt);

    if (decoded.isTrial && decoded.trialEndDate) {
      const endDate = new Date(decoded.trialEndDate);
      const currentDate = new Date();

      // Reset the hours of both dates to avoid partial day discrepancies
      endDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      const diffTime = endDate.getTime() - currentDate.getTime();
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;

      // Log trialEndDate and the calculated remaining trial days
      console.log(`Trial End Date: ${endDate}`);
      console.log(`Current Date: ${currentDate}`);
      console.log(`Days Remaining in Trial: ${diffDays}`);

      setTrialDaysRemaining(diffDays);
    }
  };

  const resetGlobalLoadingState = useCallback(() => {
    setProcessing(false);
    setLoading((prevLoading) => ({
      ...prevLoading,
      photoSelector: false,
      dateRangeSelector: false,
      updateSync: false,
    }));
  }, []);

  const handlePhotoSelector = (source) => {
    if (source === "google") {
      setIsPhotoSelectorOpen(true);
      setPhotoSource("google");
    } else if (source === "icloud") {
      setIsPhotoSelectorOpen(true);
      setPhotoSource("icloud");
    }
  };

  const handleDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(true);
  };

  const handlePhotosSelected = (() => {
    let isProcessing = false;
    let lastRequestId = null;

    return async (mediaItems) => {
      // Generate unique request ID based on content
      const requestId = mediaItems.map((item) => item.id).join("-");

      // Prevent duplicate processing
      if (isProcessing || requestId === lastRequestId) {
        console.log(
          "Request already in progress or duplicate request detected"
        );
        return;
      }

      console.log("Media items length:", mediaItems.length);
      triggerGlobalLoadingState({ photoCount: mediaItems.length });

      // Clear any previous error when user retries
      setProcessInfo((prevInfo) => ({
        ...prevInfo,
        error: null, // Clear any previous error
        processingCompleted: false,
        status: "Processing photos...",
        totalPhotos: mediaItems.length,
      }));

      try {
        isProcessing = true;
        lastRequestId = requestId;

        // Log the incoming data structure
        console.log("Received media items from PhotoSelector:", mediaItems);

        // Don't need to transform the data since PhotoSelector already provides the correct format
        const response = await axios.post(
          `/api/listPhotos`,
          {
            userSelectedPhotos: mediaItems,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("Payload sent to server:", {
          userSelectedPhotos: mediaItems,
        });

        const data = response.data;

        if (data.success) {
          setProcessInfo((prevInfo) => ({
            ...prevInfo,
            startDate: data.processInfo.startDate,
            endDate: data.processInfo.endDate,
            receiptsCount: data.processInfo.receiptsCount,
            duplicatesDeleted: data.processInfo.duplicatesDeleted,
            spreadsheetId: data.processInfo.spreadsheetId,
            lastProcessedDate: data.processInfo.lastProcessedDate,
            processingCompleted: data.processInfo.processingCompleted,
            totalPhotos: data.processInfo.totalPhotos,
            status: data.processInfo.status,
            nextSyncDate: data.processInfo.nextSyncDate,
            totalReceiptCount: data.processInfo.totalReceiptCount,
            unsubmittedReceiptCount: data.processInfo.unsubmittedReceiptCount,
            lastSubmittedDate: data.processInfo.lastSubmittedDate,
          }));
          setLastSynced(data.processInfo.endDate);
        } else {
          console.error("Error updating sync:", data.message);
          alert("Error updating sync. Please try again.");
        }
      } catch (error) {
        console.error("Error processing photos:", error);
        alert("Error processing selected photos. Please try again.");
        // Reset request tracking on error so user can retry
        lastRequestId = null;
      } finally {
        isProcessing = false;
        resetGlobalLoadingState();
      }
    };
  })();

  const handlePhotoSelectorError = (errorMessage) => {
    alert(errorMessage);
    resetGlobalLoadingState();
  };

  const handleDateRangeSelected = async (startDate, endDate) => {
    setIsDateRangeSelectorOpen(false);
    triggerGlobalLoadingState();

    const token = localStorage.getItem("token");
    if (!token) {
      resetGlobalLoadingState(); // Reset if there's no token
      return;
    }

    // Manually set processingCompleted to false to trigger the processing message
    setProcessInfo((prevInfo) => ({
      ...prevInfo,
      processingCompleted: false,
    }));

    try {
      const response = await axios.get(`/api/listPhotos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate,
          endDate,
        },
      });

      const data = response.data;

      if (data.success) {
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          startDate: data.processInfo.startDate,
          endDate: data.processInfo.endDate,
          receiptsCount: data.processInfo.receiptsCount,
          duplicatesDeleted: data.processInfo.duplicatesDeleted,
          spreadsheetId: data.processInfo.spreadsheetId,

          lastProcessedDate: data.processInfo.lastProcessedDate,
          processingCompleted: data.processInfo.processingCompleted,
          totalPhotos: data.processInfo.totalPhotos,
          status: data.processInfo.status,
          nextSyncDate: data.processInfo.nextSyncDate,
          totalReceiptCount: data.processInfo.totalReceiptCount,
          unsubmittedReceiptCount: data.processInfo.unsubmittedReceiptCount,

          lastSubmittedDate: data.lastSubmittedDate,
        }));
        setLastSynced(data.processInfo.endDate);
      } else {
        console.error("Error updating sync:", data.message);
      }
    } catch (error) {
      console.error(
        "Error updating sync:",
        error.response ? error.response.data : error.message
      );
    } finally {
      // Do not reset the loading state here. Let the effect hook handle it.
    }
  };

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(0);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);

  // Single QuickBooks initialization effect
  useEffect(() => {
    if (
      !isAccountantView ||
      !processInfo?.reports?.length ||
      quickBooksInitialized
    ) {
      return;
    }

    const initializeQuickBooks = async () => {
      try {
        const response = await fetch("/api/quickbooks/status", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to check status");
        }

        const status = await response.json();
        setQuickbooksConnectionStatus(status?.connected || false);
        setQuickBooksInitialized(true);
      } catch (err) {
        console.error("Error checking QB status:", err);
        setQuickbooksConnectionStatus(false);
        setQuickBooksInitialized(true);
      }
    };

    initializeQuickBooks();
  }, [isAccountantView, processInfo?.reports, quickBooksInitialized]); // Include quickBooksInitialized

  // Reset QuickBooks initialization when switching views
  useEffect(() => {
    setQuickBooksInitialized(false);
  }, [isAccountantView]);

  useEffect(() => {
    // Skip token processing for accountant view
    if (isAccountantView) {
      return;
    }

    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      updateSubscriptionStateFromToken(decoded);
    }

    // Storage event listener
    const handleStorageChange = () => {
      const updatedToken = localStorage.getItem("token");
      if (updatedToken) {
        const decoded = jwtDecode(updatedToken);
        updateSubscriptionStateFromToken(decoded);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [isAccountantView]);

  const handlePreviewSubmission = async () => {
    setSubmitResultMessage("");
    setShowPreview(false);
    setDataLoading(true);

    try {
      const response = await axios.get("/api/getReportData", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const { reportData, functionalTotal, homeCurrency, hasEmptyColumnR } =
        response.data;

      setFormattedReportData(reportData);

      if (reportData.length > 0) {
        setIsModalOpen(true);
        setNoOutstandingExpenses(false);
      } else {
        setNoOutstandingExpenses(true);
      }

      // Store functional total and home currency and hasEmptyColumnR
      setHasEmptyColumnR(hasEmptyColumnR);
      setReportFunctionalTotal(functionalTotal);
      setReportHomeCurrency(homeCurrency);
    } catch (error) {
      console.error("Error processing preview submission:", error.message);
    } finally {
      setDataLoading(false);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    setSubmitResultMessage("");

    try {
      const response = await axios.post(
        `/api/getReportData`,
        { action: "submit" },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      // Extract all variables including homeCurrency
      const {
        message,
        submissionDate,
        title,
        link,
        reportId,
        receiptCount,
        functionalTotal,
        homeCurrency, // Add this
      } = response.data;

      if (response.status === 200) {
        // Create new report with the proper structure, including homeCurrency
        const batchNumber = `batch-${Date.now()}`;
        const newReport = {
          batchNumber,
          link,
          reportId,
          submissionDate,
          title: title || `Expense Report - ${batchNumber}`,
          quickbooks: {
            error: null,
            status: "not_submitted",
            submittedAt: null,
            transactionId: null,
          },
          receiptCount,
          functionalTotal,
          homeCurrency, // Add this
        };

        // Update processInfo by adding the new report at the beginning of the array
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          lastSubmittedDate: submissionDate,
          reports: [newReport, ...(prevInfo.reports || [])],
        }));
        // Refresh reports from first page
        setPage(1);
        await fetchReports(1);

        setSubmitResultMessage(message || "Report submitted successfully!");
      } else {
        setSubmitResultMessage(
          message || "An error occurred while submitting the report."
        );
      }
    } catch (error) {
      const serverMessage =
        error.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
      setSubmitResultMessage(serverMessage);
    } finally {
      setFormattedReportData([]);
      setShowPreview(false);
      setSubmitLoading(false);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const hideWelcomeModal = localStorage.getItem("hideWelcomeModal");
    if (!hideWelcomeModal) {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    // When processingCompleted becomes true, reset loading states for the buttons
    if (processInfo.processingCompleted) {
      resetGlobalLoadingState();
    }
  }, [processInfo?.processingCompleted, resetGlobalLoadingState]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const confirmCancelSubscription = async () => {
    setLoading((prev) => ({ ...prev, cancelSubscription: true }));

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        resetGlobalLoadingState();
        return;
      }

      console.log("Sending cancel subscription request...");

      // Send the request to cancel the subscription
      const response = await axios.post(
        "/api/cancel-subscription",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Cancel subscription response:", response.data);

      const { newToken } = response.data;

      localStorage.setItem("token", newToken); // Save the new token
      const decoded = jwtDecode(newToken);

      // Update the state with the new subscription info
      updateSubscriptionStateFromToken(decoded);
      setUser(decoded); // Update the user state
    } catch (error) {
      console.error("Error canceling subscription:", error.message);
    } finally {
      setLoading((prev) => ({ ...prev, cancelSubscription: false }));
    }
  };

  useEffect(() => {
    if (processInfo?.automaticSyncEnabled) {
      setAutoSyncEnabled(processInfo.automaticSyncEnabled);
    }
  }, [processInfo?.automaticSyncEnabled]);

  useEffect(() => {
    if (isAccountantView) {
      return;
    }

    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }

    if (!processInfo?.processingCompleted) {
      const pollProcessingStatus = async () => {
        try {
          const response = await axios.get(`/api/getProcessState`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response.data.success) {
            setProcessInfo((prevInfo) => ({
              ...prevInfo,
              ...response.data.processInfo,
            }));
            setLastSynced(response.data.processInfo.endDate);

            if (response.data.processInfo.processingCompleted) {
              clearInterval(pollIntervalRef.current);
              pollIntervalRef.current = null;
            }
          }
        } catch (error) {
          console.error("Error polling process state:", error);
          clearInterval(pollIntervalRef.current);
          pollIntervalRef.current = null;
        }
      };

      const timeoutId = setTimeout(() => {
        pollProcessingStatus();
        pollIntervalRef.current = setInterval(pollProcessingStatus, 30000);
      }, 30000);

      return () => {
        clearTimeout(timeoutId);
        if (pollIntervalRef.current) {
          clearInterval(pollIntervalRef.current);
          pollIntervalRef.current = null;
        }
      };
    }
  }, [
    isAccountantView,
    processInfo?.processingCompleted,
    setProcessInfo,
    setLastSynced,
  ]);

  useEffect(() => {
    if (!processInfo?.processingCompleted) {
      triggerGlobalLoadingState();
    }
  }, [processInfo, triggerGlobalLoadingState]);

  const handleUpdateSync = async () => {
    triggerGlobalLoadingState();

    const token = localStorage.getItem("token");
    if (!token) {
      resetGlobalLoadingState();
      return;
    }

    if (!processInfo || !processInfo.endDate) {
      resetGlobalLoadingState();
      return;
    }

    try {
      // Manually set processingCompleted to false to trigger the processing message
      setProcessInfo((prevInfo) => ({
        ...prevInfo,
        processingCompleted: false,
      }));

      const response = await axios.get(
        `/api/listPhotos?startDate=${processInfo.endDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = response.data;

      if (data.success) {
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          startDate: data.processInfo.startDate,
          endDate: data.processInfo.endDate,
          receiptsCount: data.processInfo.receiptsCount,
          duplicatesDeleted: data.processInfo.duplicatesDeleted,
          spreadsheetId: data.processInfo.spreadsheetId,

          lastProcessedDate: data.processInfo.lastProcessedDate,
          processingCompleted: data.processInfo.processingCompleted,
          totalPhotos: data.processInfo.totalPhotos,
          status: data.processInfo.status,
          nextSyncDate: data.processInfo.nextSyncDate,
          totalReceiptCount: data.processInfo.totalReceiptCount,
          unsubmittedReceiptCount: data.processInfo.unsubmittedReceiptCount,

          lastSubmittedDate: data.processInfo.lastSubmittedDate,
        }));
        setLastSynced(data.processInfo.endDate);
      } else {
        console.error("Error updating sync:", data.message);
      }
    } catch (error) {
      console.error(
        "Error updating sync:",
        error.response ? error.response.data : error.message
      );
    } finally {
      // Do not reset the loading state here. Let the effect hook handle it.
    }
  };

  const handleToggleAutoSync = async () => {
    setLoading({ ...loading, autoSync: true });
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "/api/toggle-auto-sync",
        { autoSyncEnabled: !autoSyncEnabled },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setAutoSyncEnabled(!autoSyncEnabled);
      } else {
        console.error("Error toggling auto-sync:", response.data.message);
      }
    } catch (error) {
      console.error(
        "Error toggling auto-sync:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading({ ...loading, autoSync: false });
    }
  };

  const handleReset = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete your account, your Google Drive with your receipts, and your spreadsheet with your expenses? This action cannot be undone."
      )
    ) {
      setLoading({ ...loading, reset: true });
      try {
        const token = localStorage.getItem("token");
        await axios.post(
          "/api/reset-account",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        localStorage.removeItem("token");
        window.location.href = "/";
      } catch (error) {
        console.error("Error resetting account:", error.message);
      } finally {
        setLoading({ ...loading, reset: false });
      }
    }
  };

  const handleLogout = () => {
    setLoading({ ...loading, logout: true });
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  const openSpreadsheet = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gId}`,
      "_blank"
    );
  };

  const openSpreadsheetBilling = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gidBilling}`,
      "_blank"
    );
  };

  const openSpreadsheetExpenses = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit`,
      "_blank"
    );
  };

  const renderAllExpenses = () => (
    <div className="info-container">
      <div className="photo-selection-container" style={{ textAlign: "left" }}>
        <h3 className="photo-selection-title">Add Expenses</h3>
      </div>

      {processInfo.error && (
        <div
          className="error-message"
          style={{
            color: "#dc3545",
            marginTop: "10px",
            padding: "10px",
            fontSize: "0.9rem",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            backgroundColor: "#fff8f8",
            borderRadius: "4px",
            borderLeft: "4px solid #dc3545",
          }}
        >
          <i className="fas fa-exclamation-circle"></i>
          {processInfo.error}
        </div>
      )}
      <div className="photo-selection-container" style={{ textAlign: "left" }}>
        {/* Always show buttons */}
        <div className="photo-source-buttons">
          <button
            className="primary-button google-button"
            onClick={() => handlePhotoSelector("google")}
            disabled={
              processing ||
              loading.photoSelector ||
              !processInfo.processingCompleted
            }
          >
            <span className="button-icon google-icon"></span> Find Receipts in
            Google Photos
          </button>

          {SHOW_ICLOUD_BUTTON && (
            <button
              className="secondary-button icloud-button"
              onClick={() => handlePhotoSelector("icloud")}
              disabled={processing || loading.photoSelector}
            >
              <span className="button-icon icloud-icon"></span> Get Photos from
              iCloud
            </button>
          )}
        </div>

        {/* Loading message underneath */}
        {loading.photoSelector && (
          <div className="loading-row">
            <span className="loading-text">
              <i className="fas fa-info-circle info-icon"></i>
              {`Processing ${
                loading.photoCount > 0 ? loading.photoCount : "your"
              } photos, we will email you from info@expensebot.ai when done.`}
            </span>
          </div>
        )}
      </div>
      <div className="summary-header-container">
        <h3 className="summary-title">Expenses Summary</h3>
        <button
          onClick={openSpreadsheetExpenses}
          className="spreadsheet-view-button"
        >
          <i className="fas fa-table"></i>
          View Expense Spreadsheet
        </button>
      </div>
      <div className="summary-section">
        <div className="summary-item">
          <p className="label">Unsubmitted Receipts</p>
          <p className="value">{processInfo.unsubmittedReceiptCount ?? 0}</p>
        </div>
        <div className="summary-item">
          <p className="label">Last Sync</p>
          <p className="value">
            {processInfo.lastProcessedDate
              ? new Date(processInfo.lastProcessedDate).toLocaleDateString()
              : ""}
          </p>
        </div>

        <div className="summary-item">
          <p className="label">Recent Receipts Found</p>
          <p className="value">{processInfo.receiptsCount ?? 0}</p>
        </div>
        <div className="summary-item">
          <p className="label">Recent Duplicates Deleted</p>
          <p className="value">{processInfo.duplicatesDeleted ?? 0}</p>
        </div>
        <div className="summary-item">
          <p className="label">
            Expense Accounts
            <div
              className="info-tooltip-container"
              style={{ marginLeft: "4px" }}
            >
              <i className="fas fa-info-circle info-icon"></i>
              <div className="info-tooltip">
                <p>
                  ExpenseBot automatically classifies your expenses into the
                  expense accounts found on the Expense Account tab of your
                  spreadsheet.
                </p>
                <br />
                <div className="info-steps">
                  <p>
                    <i className="fas fa-edit"></i>
                    Review and edit expense accounts direclty in the spreadsheet
                    and ExpenseBot will do the rest.
                  </p>
                </div>
              </div>
            </div>
          </p>
          <p className="value">{processInfo.expenseCategoryCount}</p>
          {processInfo.spreadsheetId && (
            <button className="text-button" onClick={openSpreadsheet}>
              Edit
            </button>
          )}
        </div>

        <div className="summary-item">
          <p className="label">
            Tags
            <div
              className="info-tooltip-container"
              style={{ marginLeft: "4px" }}
            >
              <i className="fas fa-info-circle info-icon"></i>
              <div className="info-tooltip">
                <p>
                  Tags help you group expenses by business trip, client, or
                  project.
                </p>
                <br />
                <div className="info-steps">
                  <p>
                    <i className="fas fa-list"></i>
                    Edit Tags directly in the spreadsheet by reviewing and
                    updating the provided examples.
                  </p>
                  <p>
                    <i className="fas fa-tags"></i>
                    Add new Tags based on your needs (e.g., to group by a
                    business trip or client name)
                  </p>
                  <br />
                </div>
                <p className="info-note">
                  <i className="fas fa-lightbulb"></i> Tip: Before you take a
                  photo, write the tag name on your receipt.
                </p>
              </div>
            </div>
          </p>
          <p className="value">{processInfo.reportCategoryCount}</p>
          {processInfo.spreadsheetId && (
            <button className="text-button" onClick={openSpreadsheetBilling}>
              Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );

  // Function to render the always-visible section with the date range selector
  const renderAlwaysVisibleSection = () => (
    <div className="expense-reports-container">
      <h3 className="expense-report-title">Submit for Approval</h3>

      <div className="date-range-and-button">
        <div className="date-range-selectors">
          <div className="links-container">
            {/* Toggle Tags to Submit Link */}

            <a
              href={`https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gidBilling}`}
              target="_blank"
              rel="noopener noreferrer"
              className="link-item"
            >
              <i className="fas fa-tags"></i> Enable/Disabe Tags
            </a>

            {/* Toggle Tags to Submit Link */}

            <a
              href={`https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit`}
              target="_blank"
              rel="noopener noreferrer"
              className="link-item"
            >
              <i className="fas fa-table"></i> View Expenses
            </a>

            {/* Configure Approver Emails Link */}
            <a
              href={`https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gidSettings}`}
              target="_blank"
              rel="noopener noreferrer"
              className="link-item"
            >
              <i className="fas fa-user-check"></i> Add Recipients
            </a>
          </div>
        </div>

        {/* Preview Submission Button */}
        {/* Preview Submission Button with Info Icon */}
        <div className="button-info-container">
          <button
            className="primary-button preview-button button-width"
            onClick={handlePreviewSubmission}
          >
            Preview Submission
          </button>
          <div className="info-tooltip-container">
            <i className="fas fa-info-circle info-icon"></i>
            <div className="info-tooltip">
              <p>Create an expense report by following these steps:</p>
              <br />
              <div className="info-steps">
                <p>
                  <span className="step-number">1</span>
                  <span>
                    <span className="emphasized">Enable/Disable Tags</span> to
                    filter/include the expenses that appear in your report. Type
                    YES in the Reimburse column to include all expenses with
                    that Tag, and type NO to exclude.
                  </span>
                </p>
                <p>
                  <span className="step-number">2</span>
                  <span>
                    <span className="emphasized">View Expenses</span> to ensure
                    each expense has the correct Tag assigned. Secondly, if any
                    expenses were in a foreign currency, fill in the converted
                    amount from your credit card under Func. Total before
                    creating your report.
                  </span>
                </p>
                <p>
                  <span className="step-number">3</span>
                  <span>
                    <span className="emphasized">Add Recipients</span> to
                    confirm the email addresses to which your report will be
                    sent.
                  </span>
                </p>
                <p>
                  <span className="step-number">4</span>
                  <span>
                    Click <span className="emphasized">Preview Submission</span>{" "}
                    to see a summary of your report, then choose Submit to
                    generate it.
                  </span>
                </p>
              </div>
              <p className="info-note">
                Note: Previously submitted expenses will not appear in this
                report.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Display the submission result message here */}
      {submitResultMessage && (
        <div
          className={`message-box ${
            submitResultMessage.toLowerCase().includes("error")
              ? "error"
              : "success"
          }`}
          dangerouslySetInnerHTML={{ __html: submitResultMessage }}
        />
      )}
    </div>
  );

  const DisplayReport = ({ reportData, handleSubmit }) => (
    <div className="report-container">
      {reportData.map(({ currency, accounts, totalCurrency }) => (
        <div key={currency} className="currency-section">
          <h3>{currency}</h3>
          {accounts.map(({ account, total }) => (
            <div className="total-item" key={account}>
              <p className="total-label">{account}</p>
              <p className="total-value">${total.toFixed(2)}</p>
            </div>
          ))}
          <div className="total-item">
            <p className="total-label">
              <strong>Total {currency}:</strong>
            </p>
            <p className="total-value">${totalCurrency.toFixed(2)}</p>
          </div>
        </div>
      ))}

      {/* Submit Section */}
      <div className="submit-review-section">
        {/* Display loading spinner or submit button based on the loading state */}
        {submitLoading ? (
          <div className="loading-container">
            <TailSpin color="#0033a0" height={5} width={5} />
            <p>Submitting report, please wait...</p>
          </div>
        ) : (
          <button
            className="submit-review-button primary-button button-width"
            onClick={handleSubmit}
            disabled={submitLoading} // Disable the button while submitting
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );

  // Update your renderPreviouslySubmittedReports function in ProcessInfo.js
  const renderPreviouslySubmittedReports = () => (
    <div className="expense-reports-container">
      <div className="expense-reports-container">
        <div className="reports-header">
          <div className="reports-title-section">
            <h3
              className="expense-report-title"
              style={{ textAlign: "left", marginRight: "10px" }}
            >
              Submitted Reports
            </h3>
            <div className="info-tooltip-container">
              <i className="fas fa-info-circle info-icon"></i>
              <div className="info-tooltip">
                <p>View and manage your submitted expense reports.</p>
                <br />
                <div className="info-steps">
                  <p>
                    <i className="fas fa-eye"></i>
                    Track the status of your submitted reports
                  </p>
                  <p>
                    <i className="fas fa-filter"></i>
                    Filter reports by their payment status (Paid/Unpaid)
                  </p>
                  <p>
                    <i className="fas fa-file-invoice-dollar"></i>
                    Access the complete details of each report
                  </p>
                  <p>
                    <i className="fas fa-users"></i>
                    Invite your accountant or EA to view your reports. Share
                    this URL expensebot.ai/reviewer.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-controls">
            {(user?.roles?.type === "I-Pro" ||
              user?.roles?.type === "Employee" ||
              user?.roles?.type === "Employer") && (
              <Link to="/viewers" className="link-item">
                <i className="fas fa-share-alt"></i> Share Status
              </Link>
            )}
            <select
              value={reportFilter}
              onChange={(e) => setReportFilter(e.target.value)}
              className="status-select"
            >
              <option value="All">All Reports</option>
              <option value="Paid">Paid</option>
              <option value="Unpaid">Unpaid</option>
            </select>
          </div>
        </div>
      </div>
      {processInfo?.reports && processInfo.reports.length > 0 ? (
        loading.reports ? (
          <div className="flex justify-center items-center h-64">
            <TailSpin
              height="24"
              width="24"
              color="#0033a0"
              ariaLabel="reports-loading"
            />
          </div>
        ) : (
          <div className="report-grid">
            {processInfo.reports
              .filter((report) => {
                if (reportFilter === "All") return true;
                if (reportFilter === "Paid") return report.status === "Paid";
                return report.status !== "Paid"; // Unpaid
              })
              .map((report, index) => {
                if (!report.submissionDate || !report.link) return null;

                return (
                  <div key={index} className="report-row">
                    {/* Primary row with date, title, status and actions */}
                    <div className="report-primary-row">
                      <div className="report-date">
                        {new Date(report.submissionDate).toLocaleDateString()}
                      </div>
                      <div className="report-title">
                        <a
                          href={report.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {report.title || "Untitled Report"}
                        </a>
                      </div>
                      <div className="report-controls">
                        <select
                          value={report.status || "Submitted"}
                          onChange={(e) =>
                            handleStatusUpdate(report.reportId, e.target.value)
                          }
                          className="status-select"
                          title={report.status || "Submitted"}
                        >
                          <option value="Submitted">Submitted</option>
                          <option value="Approved">Approved</option>
                          <option value="Rejected">Rejected</option>
                          <option value="Paid">Paid</option>
                        </select>

                        <div className="action-buttons">
                          <button
                            className="action-button"
                            onClick={() => {
                              if (editingReportId === report.reportId) {
                                setEditingReportId(null);
                              } else {
                                setEditingReportId(report.reportId);
                                setEditedNote(report.notes || "");
                              }
                            }}
                            title={report.notes ? "Edit note" : "Add note"}
                          >
                            <i className="fas fa-sticky-note"></i>
                          </button>

                          {report.sentTo?.length > 0 && (
                            <div className="action-wrapper">
                              <button className="action-button">
                                <i className="fas fa-envelope"></i>
                                <span className="recipient-count">
                                  {report.sentTo.length}
                                </span>
                              </button>
                              <div className="tooltip-content">
                                <div className="tooltip-header">
                                  Recipients:
                                </div>
                                {report.sentTo.map((email, i) => (
                                  <div key={i} className="recipient">
                                    {email}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Metrics row */}
                    <div className="report-metrics">
                      <div className="metric">
                        <span className="metric-label">Receipts:</span>
                        <span className="metric-value">
                          {report.receiptCount || 0}
                        </span>
                      </div>

                      {report.functionalTotal > 0 && (
                        <div className="metric">
                          <span className="metric-label">Func. Total:</span>
                          <span className="metric-value">
                            {report.homeCurrency
                              ? formatCurrency(
                                  report.functionalTotal || 0,
                                  report.homeCurrency
                                )
                              : (report.functionalTotal || 0).toLocaleString()}
                          </span>
                        </div>
                      )}
                    </div>

                    {/* QuickBooks section */}
                    {user?.roles?.type !== "I-Basic" && (
                      <div className="quickbooks-section">
                        <ShareToQuickBooks
                          reportId={report.reportId}
                          quickbooks={report.quickbooks}
                          isConnected={quickbooksConnectionStatus}
                          onStatusUpdate={(newStatus) => {
                            setProcessInfo((prev) => ({
                              ...prev,
                              reports: prev.reports.map((r) =>
                                r.reportId === report.reportId
                                  ? {
                                      ...r,
                                      quickbooks: {
                                        ...r.quickbooks,
                                        ...newStatus,
                                      },
                                    }
                                  : r
                              ),
                            }));
                          }}
                          onConnectionStatusChange={
                            setQuickbooksConnectionStatus
                          }
                          isClientOfAccountant={isAccountantView}
                          clientEmail={
                            isAccountantView ? clientEmail : undefined
                          }
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )
      ) : (
        <p>No previously submitted reports available.</p>
      )}
      {processInfo?.reports &&
        processInfo.reports.length > 0 &&
        totalPages > 1 && (
          <div className="custom-pagination-controls">
            <button
              onClick={() => setPage((p) => Math.max(1, p - 1))}
              disabled={page === 1 || loading.reports}
              className="custom-pagination-button"
            >
              <i className="fas fa-chevron-left"></i>
            </button>
            <span className="custom-pagination-text">
              Page {page} of {totalPages}
            </span>
            <button
              onClick={() => setPage((p) => p + 1)}
              disabled={page === totalPages || loading.reports}
              className="custom-pagination-button"
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        )}
    </div>
  );

  const formatCurrency = (amount, currency) => {
    if (!currency) {
      return amount.toLocaleString(); // Just return the number if no currency specified
    }

    const currencyMap = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      JPY: "¥",
      AUD: "A$",
      CAD: "C$",
      CHF: "Fr",
      CNY: "¥",
      // Add more currencies as needed
    };

    const symbol = currencyMap[currency] || currency;
    const formattedAmount = amount.toLocaleString();

    // If it's a symbol, put it before the amount
    if (currencyMap[currency]) {
      return `${symbol}${formattedAmount}`;
    }
    // If it's a 3-letter code, put it after the amount
    return `${formattedAmount} ${symbol}`;
  };

  const formatRelativeTime = (date) => {
    const now = new Date();
    const diff = date - now;
    const diffHours = Math.floor(diff / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    if (diffHours < 0 || diffMinutes < 0) {
      return "<check auto-pilot settings>";
    }

    return `${diffHours} hours and ${diffMinutes} minutes`;
  };

  const formatUserFriendlyDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      weekday: "long",
    }).format(date);
  };

  useEffect(() => {
    const message = autoSyncEnabled ? (
      <div className="sync-info-secondary">
        <p>
          <i className="fas fa-sync-alt"></i> <strong>Next sync: </strong>
          {processInfo.nextSyncDate
            ? formatRelativeTime(new Date(processInfo.nextSyncDate))
            : "(calculating next sync...)"}
        </p>
        <p>
          <i className="fas fa-clock"></i> Last sync:
          <strong>
            {lastSynced
              ? formatUserFriendlyDate(new Date(lastSynced))
              : "(no sync data)"}
          </strong>
        </p>
      </div>
    ) : null;

    setNextSyncMessage(message);
  }, [autoSyncEnabled, processInfo.nextSyncDate, lastSynced]);

  useEffect(() => {
    if (processInfo?.endDate) {
      setLastSynced(processInfo.endDate);
    }
  }, [processInfo?.endDate]);

  const renderNotesModal = () => {
    if (!editingReportId) return null;

    return (
      <div
        className="eb-modal-overlay"
        onClick={() => setEditingReportId(null)}
      >
        <div className="eb-notes-modal" onClick={(e) => e.stopPropagation()}>
          <div className="eb-notes-content">
            <h4>Report Notes</h4>
            <textarea
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
              placeholder="Add notes about this report..."
              className="eb-notes-textarea"
              autoFocus
            />
            <div className="eb-modal-actions">
              <button
                className="eb-modal-save"
                onClick={() => handleNoteUpdate(editingReportId, editedNote)}
              >
                Save
              </button>
              <button
                className="eb-modal-cancel"
                onClick={() => setEditingReportId(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <>
        {showModal && <WelcomeModal onClose={handleCloseModal} />}
        {user && (
          <div className="profile">
            <img
              id="user-picture"
              src={user.picture || `./eblogo_192.png`}
              alt="User profile"
            />
            <div className="profile-info">
              <button className="logout-link" onClick={handleLogout}>
                Logout
              </button>
              <div className="subscription-status">
                {user.roles.type === "Employee" ? (
                  <p className="checkout-text">Corporate license</p>
                ) : (
                  <>
                    {cancelAt ? (
                      <>
                        <CheckoutButton
                          setUser={setUser}
                          updateUI={updateSubscriptionStateFromToken}
                          cancelAt={cancelAt}
                          isSubscribed={isSubscribed}
                          isTrial={isTrial}
                        />
                        <p>
                          Your subscription is set to cancel on{" "}
                          {new Date(cancelAt).toLocaleDateString()}.
                        </p>
                      </>
                    ) : isSubscribed ? (
                      <>
                        <div className="subscription-buttons">
                          <CheckoutButton
                            setUser={setUser}
                            updateUI={updateSubscriptionStateFromToken}
                            cancelAt={null}
                            isSubscribed={isSubscribed}
                            isTrial={false}
                            action="upgrade"
                          />
                          <button
                            className="cancel-subscription-link"
                            onClick={confirmCancelSubscription}
                            disabled={loading.cancelSubscription}
                          >
                            {loading.cancelSubscription ? (
                              <TailSpin
                                color="#0033a0"
                                height={24}
                                width={24}
                              />
                            ) : (
                              "Cancel Subscription"
                            )}
                          </button>
                        </div>
                        <p>
                          Next billing date{" "}
                          {new Date(subscriptionEndDate).toLocaleDateString()}.
                        </p>
                      </>
                    ) : (
                      <>
                        <CheckoutButton
                          setUser={setUser}
                          updateUI={updateSubscriptionStateFromToken}
                          cancelAt={null}
                          isSubscribed={isSubscribed}
                          isTrial={isTrial}
                        />
                        {isTrial ? (
                          <p className="checkout-text">
                            Free trial ends in {trialDaysRemaining} days
                          </p>
                        ) : (
                          <p className="checkout-text">
                            Your subscription has ended. Please subscribe to
                            continue using the service.
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="submenu">
          {/* All Expenses - shown to everyone EXCEPT Accountants */}
          <button
            className={`menu-link ${
              selectedMenu === "allExpenses" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("allExpenses")}
          >
            All Expenses
          </button>

          {/* Reports - shown to everyone except Accountants and I-Basic */}
          {((user?.roles?.accountant && user?.roles?.type !== "I-Basic") ||
            user?.roles?.type === "Employee" ||
            user?.roles?.type === "I-Pro" ||
            user?.roles?.type === "Employer" ||
            user?.subscription?.isTrial) && (
            <button
              className={`menu-link ${
                selectedMenu === "expenseReports" ? "active" : ""
              }`}
              onClick={() => handleMenuClick("expenseReports")}
            >
              Reports
            </button>
          )}

          {/* Admin View - ONLY for Accountants */}
          {user?.roles?.accountant && (
            <button
              className={`menu-link ${
                selectedMenu === "adminView" ? "active" : ""
              }`}
              onClick={() => handleMenuClick("adminView")}
            >
              Admin View
            </button>
          )}

          {/* Manage Licenses - ONLY for Employers */}
          {user?.roles?.type === "Employer" && (
            <button
              className={`menu-link ${
                selectedMenu === "licenses" ? "active" : ""
              }`}
              onClick={() => handleMenuClick("licenses")}
            >
              Manage Licenses
            </button>
          )}
        </div>

        <div className="menu-content-container">
          {selectedMenu === "allExpenses" ? (
            renderAllExpenses()
          ) : selectedMenu === "adminView" ? (
            <AdminView user={user} />
          ) : selectedMenu === "licenses" ? (
            <LicenseManager user={user} />
          ) : (
            <div>
              {/* Render the always-visible section for date range and submission */}
              {renderAlwaysVisibleSection()}
              {/* Show the loading spinner only when the report is being generated */}
              {dataLoading ? (
                <div className="loading-container">
                  <TailSpin color="#0033a0" height={20} width={20} />
                  <p>Generating report, please wait...</p>
                </div>
              ) : showPreview && formattedReportData.length > 0 ? (
                <DisplayReport
                  reportData={formattedReportData}
                  handleSubmit={handleSubmit}
                />
              ) : noOutstandingExpenses ? (
                <div className="status-message">
                  No outstanding expenses to submit.{" "}
                  <a
                    href={`https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gidBilling}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-item enhanced-link"
                  >
                    Go to the Tags tab
                  </a>{" "}
                  in your spreadsheet and turn on the Tags you want to submit
                  (add YES under the Reimburse column).
                </div>
              ) : (
                <> </> // Show nothing if noPreview or no data
              )}
              {/* Render Previously Submitted Reports Section - Always visible */}
              {renderPreviouslySubmittedReports()}
              {renderNotesModal()} {/* Notes Modal*/}
            </div>
          )}
        </div>

        <div className="container">
          {false && (
            <div className="tuning-section">
              <h2 onClick={() => toggleSection("manualSync")}>
                Manual Syncing {isManualSyncOpen ? "▲" : "▼"}
              </h2>
              {isManualSyncOpen && (
                <div className="collapsible-content">
                  <div className="grid-container">
                    {loading.photoSelector || loading.dateRangeSelector ? (
                      <div className="loading-row">
                        <span className="loading-text">
                          <i className="fas fa-info-circle info-icon"></i>
                          {`Processing ${
                            loading.photoCount > 0 ? loading.photoCount : "your"
                          } photos, we will email you from info@expensebot.ai when done.`}
                        </span>
                      </div>
                    ) : (
                      <>
                        <div className="grid-item">
                          <div>
                            <span>Photos</span>
                          </div>
                          <button
                            className="link-button"
                            onClick={() => setIsPhotoSelectorOpen(true)}
                            disabled={
                              processing ||
                              loading.photoSelector ||
                              !processInfo.processingCompleted
                            }
                          >
                            Select Photos
                          </button>

                          {isPhotoSelectorOpen && (
                            <div className="modal-backdrop">
                              <PhotoSelector
                                onClose={() => setIsPhotoSelectorOpen(false)}
                                onPhotosSelected={handlePhotosSelected}
                                onError={handlePhotoSelectorError}
                              />
                            </div>
                          )}
                        </div>

                        <div className="grid-item">
                          <span>Dates</span>
                          <button
                            className="link-button"
                            onClick={handleDateRangeSelector}
                            disabled={
                              processing ||
                              loading.dateRangeSelector ||
                              !processInfo.processingCompleted
                            }
                          >
                            Select Date Range
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {false && (
            <>
              {nextSyncMessage}
              <div className="tuning-section">
                <h2 onClick={() => toggleSection("autoPilot")}>
                  Auto Syncing {isAutoPilotOpen ? "▲" : "▼"}
                </h2>
                {isAutoPilotOpen && (
                  <div className="collapsible-content">
                    <div className="grid-container">
                      <div className="grid-item">
                        <span>Autosync: {autoSyncEnabled ? "ON" : "OFF"}</span>
                        <button
                          className="link-button"
                          onClick={handleToggleAutoSync}
                          disabled={loading.autoSync}
                        >
                          {loading.autoSync ? (
                            <TailSpin color="#0033a0" height={24} width={24} />
                          ) : autoSyncEnabled ? (
                            "Turn off"
                          ) : (
                            "Turn on"
                          )}
                        </button>
                      </div>

                      <div className="grid-item">
                        <span>Force Sync</span>
                        <button
                          className="link-button"
                          onClick={handleUpdateSync}
                          disabled={
                            processing ||
                            loading.updateSync ||
                            !processInfo.processingCompleted
                          }
                        >
                          {loading.updateSync ? (
                            <TailSpin color="#0033a0" height={24} width={24} />
                          ) : (
                            "Force Update"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {selectedMenu === "allExpenses" && (
            <div className="tuning-section">
              <h2 onClick={() => toggleSection("settings")}>
                Settings {isSettingsOpen ? "▲" : "▼"}
              </h2>
              {isSettingsOpen && (
                <div className="collapsible-content">
                  <div className="grid-container">
                    {/*  <div className="grid-item">
                      <span>Admin View</span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={adminViewEnabled}
                          onChange={toggleAdminView}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div> 
                    */}

                    {/* Reviewer Toggle */}
                    <div className="grid-item">
                      <span>Use as Reviewer</span>
                      {loading.reviewerToggle ? (
                        <TailSpin color="#0033a0" height={24} width={24} />
                      ) : (
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={user?.roles?.accountant || false}
                            onChange={handleReviewerToggle}
                            disabled={loading.reviewerToggle}
                          />
                          <span className="slider round"></span>
                        </label>
                      )}
                    </div>

                    {/* Reset Button */}
                    <div className="grid-item">
                      <span>Reset Account</span>
                      <button
                        className="link-button"
                        onClick={handleReset}
                        disabled={loading.reset}
                      >
                        {loading.reset ? (
                          <TailSpin color="#0033a0" height={24} width={24} />
                        ) : (
                          "Reset"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {isPhotoSelectorOpen &&
            (photoSource === "google" ? (
              <PhotoSelector
                onClose={() => {
                  setIsPhotoSelectorOpen(false);
                  setPhotoSource(null);
                }}
                onPhotosSelected={handlePhotosSelected}
                onError={(error) => {
                  alert(error);
                  setIsPhotoSelectorOpen(false);
                  setPhotoSource(null);
                }}
              />
            ) : (
              <ICloudPhotoSelector
                onClose={() => {
                  setIsPhotoSelectorOpen(false);
                  setPhotoSource(null);
                }}
                onPhotosSelected={handlePhotosSelected}
                onError={(error) => {
                  alert(error);
                  setIsPhotoSelectorOpen(false);
                  setPhotoSource(null);
                }}
              />
            ))}
          {isDateRangeSelectorOpen && (
            <div className="modal-backdrop">
              <DateRangeSelector
                onClose={() => setIsDateRangeSelectorOpen(false)}
                onDateRangeSelected={handleDateRangeSelected}
              />
            </div>
          )}
          {selectedMenu === "allExpenses" && (
            <footer className="footerpi">
              <div className="footer-contentpi">
                <img
                  src="./eblogo_2.png"
                  alt="ExpenseBot logo"
                  className="footer-logopi"
                />
                <p className="footer-textpi">Powered by ExpenseBot</p>

                <a href="/faq" className="faq-link">
                  FAQ
                </a>
              </div>
            </footer>
          )}
        </div>
      </>

      <ReportModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        reportData={formattedReportData}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
        submitResultMessage={submitResultMessage}
        functionalTotal={reportFunctionalTotal}
        hasEmptyColumnR={hasEmptyColumnR}
        homeCurrency={reportHomeCurrency}
      />
    </div>
  );
};

export default ProcessInfo;
