import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import ShareToQuickBooks from "./ShareToQuickBooks";
import "../styles/ProcessInfo.css";

const AccountantView = ({ clientEmail }) => {
  const [accountantData, setAccountantData] = useState(null);
  const [isLoadingAccountantView, setIsLoadingAccountantView] = useState(false);
  const [quickbooksConnectionStatus, setQuickbooksConnectionStatus] =
    useState(false);
  const [reportFilter, setReportFilter] = useState("All");
  const [editingReportId, setEditingReportId] = useState(null);
  const [editedNote, setEditedNote] = useState("");
  const navigate = useNavigate();
  const location = useLocation(); // Access location for state
  const { spreadsheetId } = location.state || {}; // Extract spreadsheetId from state

  // Add Escape key handler
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") {
        setEditingReportId(null);
      }
    };

    if (editingReportId) {
      window.addEventListener("keydown", handleEsc);
      return () => window.removeEventListener("keydown", handleEsc);
    }
  }, [editingReportId]);

  useEffect(() => {
    let isMounted = true;
    const fetchAccountantData = async () => {
      if (!clientEmail) return;

      try {
        setIsLoadingAccountantView(true);

        const response = await axios.get(`/api/client-reports/${clientEmail}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!isMounted) return;

        if (response.data.success) {
          setAccountantData(response.data.processInfo);

          if (response.data.processInfo?.reports?.length > 0) {
            const qbResponse = await fetch("/api/quickbooks/status", {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });

            if (qbResponse.ok && isMounted) {
              const status = await qbResponse.json();
              setQuickbooksConnectionStatus(status?.connected || false);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching accountant data:", error);
      } finally {
        if (isMounted) {
          setIsLoadingAccountantView(false);
        }
      }
    };

    fetchAccountantData();

    return () => {
      isMounted = false;
    };
  }, [clientEmail]);

  const handleStatusUpdate = async (reportId, newStatus) => {
    try {
      const response = await axios.post(
        "/api/accountant/update-client-report",
        {
          clientEmail,
          reportId,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "Report updated successfully") {
        setAccountantData((prev) => ({
          ...prev,
          reports: prev.reports.map((r) =>
            r.reportId === reportId ? { ...r, status: newStatus } : r
          ),
        }));
      }
    } catch (error) {
      console.error("Failed to update status:", error);
      alert("Failed to update status. Please try again.");
    }
  };

  const handleNoteUpdate = async (reportId, newNote) => {
    try {
      const response = await axios.post(
        "/api/accountant/update-client-report",
        {
          clientEmail,
          reportId,
          notes: newNote,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "Report updated successfully") {
        setAccountantData((prev) => ({
          ...prev,
          reports: prev.reports.map((r) =>
            r.reportId === reportId ? { ...r, notes: newNote } : r
          ),
        }));
        setEditingReportId(null);
      }
    } catch (error) {
      console.error("Failed to save note:", error);
      alert("Failed to save note. Please try again.");
    }
  };

  const formatCurrency = (amount, currency) => {
    if (!currency) return amount.toLocaleString();

    const currencyMap = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      JPY: "¥",
      AUD: "A$",
      CAD: "C$",
      CHF: "Fr",
      CNY: "¥",
    };

    const symbol = currencyMap[currency] || currency;
    const formattedAmount = amount.toLocaleString();
    return currencyMap[currency]
      ? `${symbol}${formattedAmount}`
      : `${formattedAmount} ${symbol}`;
  };

  const renderNotesModal = () => {
    if (!editingReportId) return null;

    return (
      <div
        className="eb-modal-overlay"
        onClick={() => setEditingReportId(null)}
      >
        <div className="eb-notes-modal" onClick={(e) => e.stopPropagation()}>
          <div className="eb-notes-content">
            <h4>Report Notes</h4>
            <textarea
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
              placeholder="Add notes about this report..."
              className="eb-notes-textarea"
              autoFocus
            />
            <div className="eb-modal-actions">
              <button
                className="eb-modal-save"
                onClick={() => handleNoteUpdate(editingReportId, editedNote)}
              >
                Save
              </button>
              <button
                className="eb-modal-cancel"
                onClick={() => setEditingReportId(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReports = () => (
    <div className="expense-reports-container">
      <div className="reports-header">
        <h3 className="expense-report-title">Submitted Reports</h3>
        <div className="filter-selector">
          <select
            value={reportFilter}
            onChange={(e) => setReportFilter(e.target.value)}
            className="status-select"
          >
            <option value="All">All Reports</option>
            <option value="Paid">Paid</option>
            <option value="Unpaid">Unpaid</option>
          </select>
        </div>
      </div>
      {accountantData?.reports && accountantData.reports.length > 0 ? (
        <div className="report-grid">
          {accountantData.reports
            .filter((report) => {
              if (reportFilter === "All") return true;
              if (reportFilter === "Paid") return report.status === "Paid";
              return report.status !== "Paid"; // Unpaid
            })
            .map((report, index) => {
              if (!report.submissionDate || !report.link) return null;

              return (
                <div key={index} className="report-row">
                  {/* Primary row with date, title, status and actions */}
                  <div className="report-primary-row">
                    <div className="report-date">
                      {new Date(report.submissionDate).toLocaleDateString()}
                    </div>
                    <div className="report-title">
                      <a
                        href={report.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {report.title || "Untitled Report"}
                      </a>
                    </div>
                    <div className="report-controls">
                      <select
                        value={report.status || "Submitted"}
                        onChange={(e) =>
                          handleStatusUpdate(report.reportId, e.target.value)
                        }
                        className="status-select"
                        title={report.status || "Submitted"}
                      >
                        <option value="Submitted">Submitted</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Paid">Paid</option>
                      </select>

                      <div className="action-buttons">
                        <button
                          className="action-button"
                          onClick={() => {
                            if (editingReportId === report.reportId) {
                              setEditingReportId(null);
                            } else {
                              setEditingReportId(report.reportId);
                              setEditedNote(report.notes || "");
                            }
                          }}
                          title={report.notes ? "Edit note" : "Add note"}
                        >
                          <i className="fas fa-sticky-note"></i>
                        </button>

                        {report.sentTo?.length > 0 && (
                          <div className="action-wrapper">
                            <button className="action-button">
                              <i className="fas fa-envelope"></i>
                              <span className="recipient-count">
                                {report.sentTo.length}
                              </span>
                            </button>
                            <div className="tooltip-content">
                              <div className="tooltip-header">Recipients:</div>
                              {report.sentTo.map((email, i) => (
                                <div key={i} className="recipient">
                                  {email}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Metrics row */}
                  <div className="report-metrics">
                    <div className="metric">
                      <span className="metric-label">Receipts:</span>
                      <span className="metric-value">
                        {report.receiptCount || 0}
                      </span>
                    </div>

                    {report.functionalTotal > 0 && (
                      <div className="metric">
                        <span className="metric-label">Func. Total:</span>
                        <span className="metric-value">
                          {report.homeCurrency
                            ? formatCurrency(
                                report.functionalTotal || 0,
                                report.homeCurrency
                              )
                            : (report.functionalTotal || 0).toLocaleString()}
                        </span>
                      </div>
                    )}
                  </div>

                  {/* QuickBooks section */}
                  <div className="quickbooks-section">
                    <ShareToQuickBooks
                      reportId={report.reportId}
                      quickbooks={report.quickbooks}
                      isConnected={quickbooksConnectionStatus}
                      onStatusUpdate={(newStatus) => {
                        setAccountantData((prev) => ({
                          ...prev,
                          reports: prev.reports.map((r) =>
                            r.reportId === report.reportId
                              ? {
                                  ...r,
                                  quickbooks: {
                                    ...r.quickbooks,
                                    ...newStatus,
                                  },
                                }
                              : r
                          ),
                        }));
                      }}
                      onConnectionStatusChange={setQuickbooksConnectionStatus}
                      isClientOfAccountant={true}
                      clientEmail={clientEmail}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <p>No reports available.</p>
      )}
    </div>
  );

  return (
    <div className="accountant-view-container">
      <button className="back-button" onClick={() => navigate("/process-info")}>
        <i className="fas fa-arrow-left"></i>
        Back to Dashboard
      </button>

      {isLoadingAccountantView ? (
        <div className="loading-spinner">
          <TailSpin color="#0033a0" height={50} width={50} />
        </div>
      ) : (
        <>
          <div className="client-header">
            <h3 className="client-name">
              {accountantData?.clientName || ""}
              <span className="client-email">
                {accountantData?.clientEmail
                  ? `(${accountantData.clientEmail})`
                  : ""}
              </span>
            </h3>
            {spreadsheetId && (
              <a
                href={`https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit`}
                target="_blank"
                rel="noopener noreferrer"
                className="spreadsheet-link"
              >
                <i className="fas fa-exclamation-triangle spreadsheet-icon"></i>
                Live expenses spreadsheet and user settings
              </a>
            )}
          </div>
          {renderReports()}
          {renderNotesModal()} {/* Added the modal here */}
        </>
      )}
    </div>
  );
};

export default AccountantView;
