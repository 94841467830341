import React, { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import "../styles/AdminView.css";

const LicenseManager = ({ user }) => {
  const [licenses, setLicenses] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [stats, setStats] = useState({
    available: 0,
    used: 0,
    total: 0,
  });

  useEffect(() => {
    fetchLicenses();
  }, []);

  const fetchLicenses = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/licenses", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      setLicenses(response.data.assignedLicenses || []);
      setStats({
        available: response.data.availableLicenses || 0,
        used: (response.data.assignedLicenses || []).length,
        total: response.data.licenseCount || 0,
      });
    } catch (err) {
      console.error("Error fetching licenses:", err);
      setError("Failed to load licenses");
    } finally {
      setLoading(false);
    }
  };

  const assignLicense = async (e) => {
    e.preventDefault();
    if (!newEmail.trim()) {
      setError("Please enter an email address");
      return;
    }

    setLoading(true);
    setError("");

    try {
      await axios.post(
        "/api/licenses/assign",
        { email: newEmail.trim() },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setNewEmail("");
      await fetchLicenses(); // Refresh the list
    } catch (err) {
      setError(err.response?.data?.message || "Failed to assign license");
    } finally {
      setLoading(false);
    }
  };

  const revokeLicense = async (email) => {
    if (
      !window.confirm(
        `Are you sure you want to revoke the license for ${email}?`
      )
    ) {
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        "/api/licenses/revoke",
        { email },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      await fetchLicenses(); // Refresh the list
    } catch (err) {
      setError("Failed to revoke license");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-view-container">
      <div className="main-invite-section">
        <h3>Manage Team Licenses</h3>

        {/* License Statistics */}
        <div className="license-stats">
          <div className="stat-box">
            <span className="stat-label">Total Licenses</span>
            <span className="stat-value">{stats.total}</span>
          </div>
          <div className="stat-box">
            <span className="stat-label">Available</span>
            <span className="stat-value">{stats.available}</span>
          </div>
          <div className="stat-box">
            <span className="stat-label">Assigned</span>
            <span className="stat-value">{stats.used}</span>
          </div>
        </div>

        {/* License Assignment Form */}
        <form onSubmit={assignLicense} className="license-form">
          <div className="input-group">
            <input
              type="email"
              placeholder="Enter email address"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              className="client-input"
              disabled={loading || stats.available === 0}
            />
          </div>
          <button
            type="submit"
            className="primary-button invite-button"
            disabled={loading || stats.available === 0}
          >
            {loading ? (
              <div className="loading-button-content">
                <TailSpin color="#ffffff" height={16} width={16} />
                <span>Assigning...</span>
              </div>
            ) : (
              "Assign License"
            )}
          </button>
          {error && <p className="error-message">{error}</p>}
          {stats.available === 0 && (
            <p className="warning-message">No licenses available to assign</p>
          )}
        </form>

        {/* Assigned Licenses Table */}
        <div className="client-table-container">
          <table className="client-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Status</th>
                <th>Assigned Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {licenses.map((license) => (
                <tr key={license.email}>
                  <td>{license.email}</td>
                  <td>
                    <span
                      className={`status-badge ${license.status.toLowerCase()}`}
                    >
                      {license.status}
                    </span>
                  </td>
                  <td>{new Date(license.assignedAt).toLocaleDateString()}</td>
                  <td>
                    <button
                      onClick={() => revokeLicense(license.email)}
                      className="text-button delete-button"
                      disabled={loading}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LicenseManager;
