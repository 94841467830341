import React, { useEffect } from "react"; // Add useEffect import
import "../styles/Home.css";

const Home = () => {
  useEffect(() => {
    // Get token from URL path if it exists
    const pathParts = window.location.pathname.split("/");
    if (pathParts[1] === "invite" && pathParts[2]) {
      const inviteToken = pathParts[2];
      localStorage.setItem("pendingInviteToken", inviteToken);
      localStorage.setItem("returnPath", `/invite/${inviteToken}`);
      console.log("Stored invite token on load:", inviteToken);
    }
  }, []);

  const handleGoogleLogin = () => {
    // Just redirect to auth - the stored token (if any) will be handled after login
    window.location.href = "/api/auth?skipConsent=true";
  };

  return (
    <div className="homecontainer">
      <header>
        <div className="header-content">
          <h1 className="logo">
            <img src="/eblogo_2.png" alt="ExpenseBot logo" />
          </h1>
          <h2 className="headline">
            Effortless <span className="highlight2">Expense Management</span>
          </h2>
          <p className="subheadline">
            Simplify your expense reporting, save time, and get paid faster with
            ExpenseBot.
          </p>
        </div>
      </header>

      <div className="login-section call-to-action">
        <div className="login-buttons">
          <button className="login-button google" onClick={handleGoogleLogin}>
            <img src="/g-logo.png" alt="Google" className="provider-icon" />
            Login with Google
          </button>
        </div>
      </div>

      <main className="content1">
        <div className="marketing-grid">
          <div className="marketing-item large-icon snap-receipt">
            <div className="image-container">
              <img src="/phone3.png" alt="Snap" className="feature-image" />
            </div>
            <h3>Snap a Receipt</h3>
            <p>No app required. Just take a photo like you normally would.</p>
          </div>
          <div className="marketing-item large-icon autosync">
            <div className="image-container">
              <img
                src="/middleimage.png"
                alt="Expense auto-classification visualization"
                className="feature-image"
              />
            </div>
            <h3>Auto Classification</h3>
            <p>
              ExpenseBot automatically organizes expenses by category (meals,
              travel, lodging) and groups them by project, client, or trip - all
              with zero effort.
            </p>
          </div>
          <div className="marketing-item large-icon ai-processing">
            <div className="image-container">
              <img
                src="/3rdphoto.png"
                alt="Smart expense report with receipt"
                className="feature-image"
              />
            </div>
            <h3>Smart Reports</h3>
            <p>
              Automatically creates professional expense reports w/ links to
              each receipt photo.
            </p>
          </div>
        </div>

        {/* Inside your main Home component, replace the benefits section with this: */}

        <div className="benefits-section">
          <h2>Save Time, Get Paid Faster</h2>
          <div className="benefits-grid">
            <div className="benefit-item">
              <div className="benefit-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                  <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                  <circle cx="12" cy="3" r="2"></circle>
                </svg>
              </div>
              <h3>Business Travelers & Teams</h3>
              <div className="benefit-details">
                <p>
                  Stop chasing paper receipts. Submit expenses instantly and get
                  reimbursed faster.
                </p>
                <ul className="benefit-features">
                  <li>Snap and forget - we handle the rest</li>
                  <li>Expenses organized by trip and project</li>
                  <li>Get paid back faster</li>
                </ul>
              </div>
            </div>
            <div className="benefit-item">
              <div className="benefit-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                  <path d="M12 11h4"></path>
                  <path d="M12 16h4"></path>
                  <path d="M8 11h.01"></path>
                  <path d="M8 16h.01"></path>
                </svg>
              </div>
              <h3>EAs & Accounting Teams</h3>
              <div className="benefit-details">
                <p>
                  Streamline expense processing for your entire organization.
                  Save hours of manual work.
                </p>
                <ul className="benefit-features">
                  <li>Automated receipt verification</li>
                  <li>Smart categorization & grouping</li>
                  <li>Ready-to-approve reports</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="footerhome">
        <p className="terms">
          By signing up, you agree to the ExpenseBot{" "}
          <a href="/privacy">Privacy Policy</a> and{" "}
          <a href="/terms">Terms of Service</a>. <br />
          Have questions? Check out our <a href="/faq">FAQ</a>.
        </p>
      </footer>
    </div>
  );
};

export default Home;
