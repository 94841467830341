import React from "react";
//import "../styles/ProcessInfo.css";
import "../styles/ReportModal.css";

import { TailSpin } from "react-loader-spinner";

const ReportModal = ({
  isOpen,
  onClose,
  reportData,
  handleSubmit,
  submitLoading,
  submitResultMessage,
  functionalTotal,
  homeCurrency,
  hasEmptyColumnR,
}) => {
  if (!isOpen) return null;

  const formatCurrency = (amount, currency) => {
    if (!currency) {
      return amount.toLocaleString();
    }

    const currencyMap = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      JPY: "¥",
      AUD: "A$",
      CAD: "C$",
      CHF: "Fr",
      CNY: "¥",
    };

    const symbol = currencyMap[currency] || currency;
    const formattedAmount = amount.toLocaleString();

    return currencyMap[currency]
      ? `${symbol}${formattedAmount}`
      : `${formattedAmount} ${symbol}`;
  };

  return (
    <div className="report-modal-backdrop">
      <div className="report-modal-wrapper">
        <div className="report-modal-header">
          <button className="report-modal-close" onClick={onClose}>
            &times;
          </button>
          <h2 className="report-modal-title">Preview Report</h2>
        </div>
        <div className="report-modal-content">
          {reportData.length > 0 ? (
            <>
              <div className="report-currency-section">
                {reportData.map(({ currency, accounts, totalCurrency }) => (
                  <div className="report-currency-group" key={currency}>
                    <h3 className="report-currency-title">{currency}</h3>
                    {accounts.map(({ account, total }) => (
                      <div className="report-total-row" key={account}>
                        <p className="report-total-label">{account}</p>
                        <p className="report-total-value">
                          ${total.toFixed(2)}
                        </p>
                      </div>
                    ))}
                    <div className="report-total-row">
                      <p className="report-total-label">
                        <strong>Total {currency}:</strong>
                      </p>
                      <p className="report-total-value">
                        ${totalCurrency.toFixed(2)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="report-functional-total">
                <div className="report-total-row">
                  <p className="report-total-label">
                    <strong>Functional Total:</strong>
                  </p>
                  <p className="report-total-value">
                    <strong>
                      {homeCurrency
                        ? formatCurrency(functionalTotal, homeCurrency)
                        : formatCurrency(functionalTotal)}
                    </strong>
                  </p>
                </div>
              </div>
              {hasEmptyColumnR && (
                <div className="report-alert">
                  <i className="fas fa-exclamation-triangle"></i> An empty
                  'Func. Total' cell was detected. Check your spreadsheet
                  foreign currency expenses or for a mismatch with your home
                  currency setting.
                </div>
              )}
            </>
          ) : (
            <p>No report data to display</p>
          )}

          <div className="report-submit-section">
            {submitLoading ? (
              <div className="report-loading-container">
                <TailSpin color="#0033a0" height={20} width={20} />
                <p>Submitting report, please wait...</p>
              </div>
            ) : (
              <button
                className="report-submit-button"
                onClick={handleSubmit}
                disabled={submitLoading}
              >
                Submit
              </button>
            )}
            {submitResultMessage && (
              <div
                className={`report-message ${
                  submitResultMessage.includes("error")
                    ? "report-message-error"
                    : "report-message-success"
                }`}
              >
                {submitResultMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
