import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom"; // Add useLocation here
import "../styles/AdminView.css";

const AdminView = ({ user }) => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newClientName, setNewClientName] = useState("");
  const [newClientEmail, setNewClientEmail] = useState("");
  const [inviteError, setInviteError] = useState("");
  const [isInviting, setIsInviting] = useState(false);
  const [isResending, setIsResending] = useState({});
  const location = useLocation(); // Add this to handle URL parameters

  // Add this new effect to handle referral URLs

  const TabSelector = () => (
    <div className="tab-container">
      <button className="tab-button active">Request Access</button>
    </div>
  );

  const handleDeleteAccess = async (clientEmail) => {
    try {
      const response = await axios.post(
        "/api/accountant/remove-relationship",
        {
          email: clientEmail,
          type: "access",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        await fetchClients(); // Refresh the list
      }
    } catch (error) {
      console.error("Error removing access:", error);
    }
  };

  const handleResendInvite = async (email, name) => {
    setIsResending((prev) => ({ ...prev, [email]: true }));

    try {
      const response = await axios.post(
        "/api/accountant/invite-client",
        {
          clientName: name,
          clientEmail: email,
          resend: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.data.success) {
        throw new Error(response.data.message || "Failed to resend invite");
      }
    } catch (error) {
      console.error("Error resending invite:", error);
      setInviteError(`Failed to resend invite to ${email}`);
    } finally {
      setIsResending((prev) => ({ ...prev, [email]: false }));
    }
  };

  const fetchClients = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/api/accountant/clients", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (response.data.success) {
        setClients(response.data.clients);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleInviteClient = useCallback(
    async (e, referralEmail = null) => {
      if (e) e.preventDefault();
      setInviteError("");
      setIsInviting(true);

      const emailToUse = referralEmail || newClientEmail;
      const nameToUse = referralEmail || newClientName; // Use email as name for referrals

      if (!referralEmail && (!nameToUse.trim() || !emailToUse.trim())) {
        setInviteError("Please provide both client name and email");
        setIsInviting(false);
        return;
      }

      if (!validateEmail(emailToUse.trim())) {
        setInviteError("Please enter a valid email address");
        setIsInviting(false);
        return;
      }

      try {
        const response = await axios.post(
          "/api/accountant/invite-client",
          {
            clientName: nameToUse.trim(),
            clientEmail: emailToUse.trim(),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setNewClientName("");
          setNewClientEmail("");
          await fetchClients();
        } else {
          setInviteError(response.data.message || "Failed to invite client");
        }
      } catch (error) {
        setInviteError("Error inviting client. Please try again.");
        console.error("Error inviting client:", error);
      } finally {
        setIsInviting(false);
      }
    },
    [newClientEmail, newClientName, fetchClients]
  ); // Add dependencies here

  // Then update the useEffect with handleInviteClient in dependencies
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const referralEmail = urlParams.get("referral");

    if (referralEmail && user) {
      // Clean up URL parameters immediately
      window.history.replaceState({}, document.title, window.location.pathname);

      // Clean up localStorage
      const hasProcessedReferral = localStorage.getItem("processedReferral");
      if (!hasProcessedReferral) {
        handleInviteClient(null, referralEmail);
        localStorage.setItem("processedReferral", "true");
      }
    }

    // Cleanup function
    return () => {
      localStorage.removeItem("processedReferral");
    };
  }, [user, location, handleInviteClient]);

  const renderClientTable = () => {
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <TailSpin color="#0033a0" height={20} width={20} />
        </div>
      );
    }

    if (clients.length === 0) {
      return <div className="no-clients-message">No current clients</div>;
    }

    return (
      <div className="client-table-container">
        <table className="client-table">
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={client.email}>
                <td>
                  <div className="name-with-email">
                    {client.name}
                    <button className="action-button">
                      <i className="fas fa-envelope"></i>
                      <div className="tooltip-content">
                        <div className="tooltip-header">Email:</div>
                        <div className="recipient">{client.email}</div>
                      </div>
                    </button>
                  </div>
                </td>{" "}
                <td>{client.email}</td>
                <td>
                  {client.status === "accepted" ? (
                    <div className="action-buttons">
                      <Link
                        to={`/client/${encodeURIComponent(
                          client.email
                        )}/reports`}
                        className="view-link"
                        state={{
                          isAccountantView: true,
                          spreadsheetId: client.spreadsheetId,
                        }}
                      >
                        <button className="view-button">
                          <i className="fas fa-chart-bar"></i>
                          View Reports
                        </button>
                      </Link>
                      <button
                        onClick={() => handleDeleteAccess(client.email)}
                        className="text-button delete-button"
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="status-actions">
                      <span className="status-badge pending">Pending</span>
                      <button
                        onClick={() =>
                          handleResendInvite(client.email, client.name)
                        }
                        className="text-button resend-link"
                        disabled={isResending[client.email]}
                      >
                        {isResending[client.email] ? (
                          <div className="loading-button-content">
                            <TailSpin color="#ffffff" height={12} width={12} />
                            <span>Resending...</span>
                          </div>
                        ) : (
                          "Resend"
                        )}
                      </button>
                      <button
                        onClick={() => handleDeleteAccess(client.email)}
                        className="text-button delete-button"
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  /* 
  /* Sub menu for email referral
  /* 
  /* const InviteHelper = () => {
    const [copied, setCopied] = useState(false);
    const inviteUrl = `${process.env.REACT_APP_BASE_URL}/signup?referral=${user.email}`;

    const handleCopy = () => {
      navigator.clipboard.writeText(inviteUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };

    return (
      <div className="invite-helper-box">
        <h4>Grant access to your expense reports</h4>
        <p>Share this link with them to get started:</p>
        <div className="invite-link-container">
          <input
            type="text"
            readOnly
            value={inviteUrl}
            className="invite-link-input"
          />
          <button onClick={handleCopy} className="copy-button">
            <i className={`fas ${copied ? "fa-check" : "fa-copy"}`}></i>
            {copied ? "Copied!" : "Copy Link"}
          </button>
        </div>
        <p className="helper-note">
          Once they sign up, they will be able to see and edit your expense
          reports.
        </p>
      </div>
    );
  }; */

  return (
    <div className="admin-view-container">
      <TabSelector />
      <div className="main-invite-section">
        <h3>Request Access to Someone's Reports</h3>
        <form onSubmit={handleInviteClient}>
          <div className="input-group">
            <input
              type="text"
              placeholder="User Name"
              value={newClientName}
              onChange={(e) => setNewClientName(e.target.value)}
              className="client-input"
            />
          </div>
          <div className="input-group">
            <input
              type="email"
              placeholder="Client Email"
              value={newClientEmail}
              onChange={(e) => setNewClientEmail(e.target.value)}
              className="client-input"
            />
          </div>
          <button
            type="submit"
            className="primary-button invite-button"
            disabled={isInviting}
          >
            {isInviting ? (
              <div className="loading-button-content">
                <TailSpin color="#ffffff" height={16} width={16} />
                <span>Sending...</span>
              </div>
            ) : (
              "Send Invite"
            )}
          </button>
          {inviteError && <p className="error-message">{inviteError}</p>}
        </form>
        {renderClientTable()}
      </div>
    </div>
  );
};

export default AdminView;
