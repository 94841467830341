import React, { useState } from "react";
import "../styles/Modal.css";

const WelcomeModal = ({ onClose }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleCheckboxChange = (e) => {
    setDontShowAgain(e.target.checked);
  };

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem("hideWelcomeModal", "true");
    }
    onClose();
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2 className="step-title">One-time Setup!</h2>
        <p className="step-description left-aligned">
          Finish your one-time setup:
        </p>
        <ul className="step-list left-aligned">
          <li className="step-item">
            <span className="step-number">1</span> Upload a Photo of a receipt
            from the Google button on the main screen
          </li>
          <li className="step-item">
            <span className="step-number">2</span> View your Expenses
            Spreadsheet
          </li>
          <li className="step-item">
            <span className="step-number">3</span> Configure Expense Accounts on
            the Expense Accounts tab of your spreadsheet
          </li>
          <li className="step-item">
            <span className="step-number">4</span> Configure Tags on the Tags
            tab of your spreadsheet
          </li>
        </ul>

        <div className="important-info">
          <i className="fas fa-shield-alt"></i> Add{" "}
          <strong>info@expensebot.ai </strong>
          to your safe sender list
        </div>
        <div className="modal-footer">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={dontShowAgain}
              onChange={handleCheckboxChange}
            />
            <span className="checkbox-label">Don't show this again</span>
          </label>
          <button onClick={handleClose} className="close-button">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
