import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/faq.css";

const TERMSPage = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/"); // Navigate back to the home page
  };

  return (
    <div className="faq-container">
      <header className="faq-header">
        <button className="back-button" onClick={handleBackClick}>
          ← Back
        </button>
        <div className="logo-container">
          <a href="/">
            <img
              src="./eblogo_2.png"
              alt="ExpenseBot Logo"
              className="faq-logo"
            />
          </a>
        </div>
        <div className="faq-title-container">
          <h1 className="faq-title">Terms of Service</h1>
        </div>
      </header>

      <div className="faq-item">
        <p className="faq-question">1. Acceptance of Terms</p>
        <p className="faq-answer">
          By using ExpenseBot ("Service"), you agree to be bound by these terms.
          If you do not agree, you may not use the Service.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">2. Use of the Service</p>
        <p className="faq-answer">
          You agree to use the Service only for lawful purposes and in a manner
          that does not infringe the rights of, or restrict or inhibit the use
          of the Service by any third party.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">3. User Content</p>
        <p className="faq-answer">
          You retain ownership of any data you upload to the Service. However,
          by uploading, you grant ExpenseBot the right to use, modify, and
          display your content to provide the Service.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">4. Termination</p>
        <p className="faq-answer">
          We reserve the right to suspend or terminate your access to the
          Service at any time, without notice, if you violate these terms.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">5. Changes to Terms</p>
        <p className="faq-answer">
          ExpenseBot reserves the right to modify these terms at any time. Your
          continued use of the Service after changes constitutes acceptance of
          the new terms.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">6. Limitation of Liability</p>
        <p className="faq-answer">
          To the maximum extent permitted by law, ExpenseBot shall not be liable
          for any indirect or consequential damages arising from your use of the
          Service.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">7. Contact Information</p>
        <p className="faq-answer">
          If you have any questions about these Terms, please contact us at
          info@expensebot.ai.
        </p>
      </div>
    </div>
  );
};

export default TERMSPage;
