import React, { useState, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import {
  createPickerSession,
  pollSession,
  fetchSelectedMediaItems,
} from "./googlePhotosPicker";
import "../styles/PhotoSelector.css";

const PHOTO_LIMIT = process.env.REACT_APP_NEXT_PUBLIC_PHOTO_LIMIT || 100;

const createSessionManager = () => {
  let currentRequest = null;

  return {
    isProcessing: () => !!currentRequest,
    getCurrentRequest: () => currentRequest,
    startRequest: () => {
      currentRequest = {};
      return currentRequest;
    },
    endRequest: (request) => {
      if (request === currentRequest) {
        currentRequest = null;
      }
    },
  };
};

const PhotoSelector = ({ onClose, onPhotosSelected, onError }) => {
  const [pickerUri, setPickerUri] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [status, setStatus] = useState("initializing");
  const [pickerMode, setPickerMode] = useState("web");
  const [hasStartedSelection, setHasStartedSelection] = useState(false);
  const [validPhotoCount, setValidPhotoCount] = useState(0);
  const [selectionError, setSelectionError] = useState(null);
  const [exceededLimit, setExceededLimit] = useState(false);
  // Add to PhotoSelector.js
  const [lastPollingTime, setLastPollingTime] = useState(Date.now());
  const [canManuallyRefresh, setCanManuallyRefresh] = useState(false);
  const checkSelectionRef = React.useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const sessionManager = React.useRef(createSessionManager());
  const visibilityTimeoutRef = React.useRef(null);

  // Add this at the top of your component, outside the component function

  const openPickerInPopup = (url) => {
    try {
      const width = Math.min(600, window.innerWidth * 0.8);
      const height = Math.min(600, window.innerHeight * 0.8);
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2;

      const features = [
        `width=${width}`,
        `height=${height}`,
        `top=${top}`,
        `left=${left}`,
        "resizable=yes",
        "scrollbars=yes",
        "status=yes",
        "location=yes",
      ].join(",");

      const pickerWindow = window.open(url, "GooglePicker", features);

      if (pickerWindow) {
        // Try to focus the popup
        pickerWindow.focus();

        // Store reference to original window
        try {
          pickerWindow.opener = window;
        } catch (e) {
          console.warn("Could not set opener reference:", e);
        }
      }

      return pickerWindow;
    } catch (error) {
      console.error("Error opening popup:", error);
      return null;
    }
  };

  const handleVisibilityChange = React.useCallback(() => {
    if (document.visibilityState === "visible" && isMobile) {
      // Clear any existing timeout
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current);
      }

      // Get stored session state
      const storedState = localStorage.getItem("photoPickerState");
      if (storedState) {
        try {
          const { sessionId: storedSessionId } = JSON.parse(storedState);
          if (storedSessionId === sessionId) {
            // Debounce the check to prevent rapid multiple fires
            visibilityTimeoutRef.current = setTimeout(() => {
              if (checkSelectionRef.current) {
                checkSelectionRef.current();
              }
            }, 1000);
          }
        } catch (error) {
          console.warn("Error parsing stored picker state:", error);
        }
      }
    }
  }, [isMobile, sessionId]);

  useEffect(() => {
    const checkMobile = () => {
      const mobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      setIsMobile(mobile);
      if (mobile) {
        setPickerMode("web"); // Force web mode on mobile devices
      }
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    // Store session state in localStorage
    if (sessionId && hasStartedSelection) {
      localStorage.setItem(
        "photoPickerSession",
        JSON.stringify({
          sessionId,
          startTime: Date.now(),
          lastPoll: Date.now(),
        })
      );
    }
  }, [sessionId, hasStartedSelection]);

  // On component mount, check for existing session
  useEffect(() => {
    const savedSession = localStorage.getItem("photoPickerSession");
    if (savedSession) {
      const { sessionId: savedId, startTime } = JSON.parse(savedSession);
      // If session is less than 30 mins old, restore it
      if (Date.now() - startTime < 30 * 60 * 1000) {
        setSessionId(savedId);
        setHasStartedSelection(true);
        setStatus("waiting");
      } else {
        localStorage.removeItem("photoPickerSession");
      }
    }
  }, []);

  useEffect(() => {
    // If no updates for 30 seconds, allow manual refresh
    const timer = setTimeout(() => {
      setCanManuallyRefresh(true);
    }, 30000);

    return () => clearTimeout(timer);
  }, [lastPollingTime]);

  useEffect(() => {
    // Cleanup function
    return () => {
      // Clear session data on unmount
      localStorage.removeItem("photoPickerSession");
      localStorage.removeItem("photoPickerState"); // Add this
      // Clear any pending timers
      setCanManuallyRefresh(false);
      if (visibilityTimeoutRef.current) {
        // Add this
        clearTimeout(visibilityTimeoutRef.current);
      }
    };
  }, []);

  // Initialize picker session
  useEffect(() => {
    const initSession = async () => {
      try {
        const { sessionId, pickerUri } = await createPickerSession();
        setSessionId(sessionId);
        setPickerUri(pickerUri);
        setStatus("selecting");
      } catch (error) {
        console.error("Failed to create session:", error);
        // Handle specific errors from Google Photos API
        if (error.response && error.response.status === 403) {
          onError?.(
            "It looks like your Google Photos library isn't accessible. Please ensure Google Photos is set up and you have granted the necessary permissions."
          );
        } else if (error.response && error.response.status === 404) {
          onError?.(
            "No Google Photos library found. Please set up Google Photos to use this feature."
          );
        } else {
          onError?.("Failed to start photo selection. Please try again.");
        }

        // Close the picker if an error occurs
        onClose();
      }
    };

    initSession();
  }, [onClose, onError]);

  useEffect(() => {
    let timeoutId;
    const MOBILE_POLL_INTERVAL = 10000; // 10 seconds for mobile
    const DESKTOP_POLL_INTERVAL = 5000; // 5 seconds for desktop
    let lastPollTime = Date.now();

    const checkSelection = async () => {
      // Use session manager to prevent concurrent polling
      if (sessionManager.current.isProcessing()) {
        console.log("Polling already in progress, skipping...");
        return;
      }

      // For web picker, maintain existing behavior requiring manual start
      if (pickerMode === "web" && !hasStartedSelection) return;

      // For mobile, we'll check regardless of hasStartedSelection
      if (!sessionId) return;

      const currentRequest = sessionManager.current.startRequest();

      try {
        lastPollTime = Date.now();

        const session = await pollSession(sessionId);
        console.log("Poll session response:", session);

        // Verify this request is still valid
        if (currentRequest !== sessionManager.current.getCurrentRequest()) {
          console.log("Request superseded, discarding results");
          return;
        }

        // Auto-transition for mobile mode only
        if (
          pickerMode === "mobile" &&
          session.state === "SELECTION_IN_PROGRESS" &&
          !hasStartedSelection
        ) {
          console.log(
            "Detected mobile user selection activity, transitioning to waiting screen"
          );
          setHasStartedSelection(true);
          setStatus("waiting");
        }

        if (session.mediaItemsSet) {
          // Clear any existing poll timeout
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          // For mobile mode, ensure we're in the right state if we haven't set it yet
          if (pickerMode === "mobile" && !hasStartedSelection) {
            setHasStartedSelection(true);
            setStatus("waiting");
          }

          const mediaItems = await fetchSelectedMediaItems(sessionId);

          // Check again if request is still valid after fetching items
          if (currentRequest !== sessionManager.current.getCurrentRequest()) {
            console.log(
              "Request superseded after fetching media items, discarding results"
            );
            return;
          }

          console.log("Raw media items from Picker:", mediaItems);

          if (!Array.isArray(mediaItems) || mediaItems.length === 0) {
            throw new Error("No media items selected or invalid response.");
          }

          // Rest of your existing media processing code...
          if (mediaItems.length > PHOTO_LIMIT) {
            setExceededLimit(true);
            setValidPhotoCount(mediaItems.length);
            setStatus("error");
            setSelectionError(
              `You've selected ${mediaItems.length} photos, which exceeds the limit of ${PHOTO_LIMIT}. Please make a new selection with fewer photos.`
            );
            return;
          }

          const photoItems = mediaItems.filter((item) => {
            if (item.type !== "PHOTO") {
              console.warn(`Skipping non-photo item with type: ${item.type}`);
              return false;
            }

            if (!item.id || !item.baseUrl || !item.mimeType) {
              console.warn(
                `Skipping item missing required fields:`,
                JSON.stringify(
                  {
                    hasId: !!item.id,
                    hasBaseUrl: !!item.baseUrl,
                    hasMimeType: !!item.mimeType,
                  },
                  null,
                  2
                )
              );
              return false;
            }

            return true;
          });

          if (photoItems.length === 0) {
            setSelectionError(
              "No valid photos found in your selection. Please try again."
            );
            setStatus("error");
            return;
          }

          const transformedPhotos = photoItems.map((item) => ({
            id: item.id,
            baseUrl: item.baseUrl,
            mimeType: item.mimeType,
            filename: item.filename || `photo_${item.id}.jpg`,
            mediaMetadata: {
              creationTime:
                item.mediaMetadata?.creationTime || new Date().toISOString(),
            },
          }));

          if (photoItems.length > 0) {
            setSelectionError(null);
            setValidPhotoCount(photoItems.length);
            setStatus("processing");

            console.log(`Processing ${photoItems.length} photos...`);
            setTimeout(() => {
              onPhotosSelected(transformedPhotos);
              onClose();
            }, 1000);
          }
        } else {
          // Calculate next poll interval based on device type
          const pollInterval = isMobile
            ? MOBILE_POLL_INTERVAL
            : DESKTOP_POLL_INTERVAL;

          // Ensure minimum time between polls
          const timeSinceLastPoll = Date.now() - lastPollTime;
          const adjustedInterval = Math.max(
            0,
            pollInterval - timeSinceLastPoll
          );

          // Continue polling
          timeoutId = setTimeout(checkSelection, adjustedInterval);
        }
      } catch (error) {
        console.error("Error processing selected photos:", error);
        onError?.(
          error.message || "Error processing selected photos. Please try again."
        );
        onClose();
      } finally {
        sessionManager.current.endRequest(currentRequest);
      }
    };

    // Store the function reference
    checkSelectionRef.current = checkSelection;

    // Add visibility listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Start checking
    checkSelection();

    // Cleanup
    // Cleanup
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current);
      }
      // Add popup cleanup
      const storedState = localStorage.getItem("photoPickerState");
      if (storedState) {
        try {
          const { popup } = JSON.parse(storedState);
          if (popup?.checkIntervalId) clearInterval(popup.checkIntervalId);
          if (popup?.timeoutId) clearTimeout(popup.timeoutId);
        } catch (error) {
          console.warn("Error cleaning up popup timers:", error);
        }
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      localStorage.removeItem("photoPickerState");
    };
  }, [
    sessionId,
    hasStartedSelection,
    pickerMode,
    onClose,
    onError,
    onPhotosSelected,
    isMobile,
    handleVisibilityChange,
  ]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("photoPickerSession");
    };
  }, []);

  const handleManualRefresh = async () => {
    if (checkSelectionRef.current) {
      setCanManuallyRefresh(false);
      setLastPollingTime(Date.now());
      try {
        await checkSelectionRef.current();
      } catch (error) {
        console.error("Manual refresh failed:", error);
        // Allow another refresh attempt immediately if it fails
        setCanManuallyRefresh(true);
      }
    }
  };

  const handleRetry = async () => {
    try {
      // Clear pickerUri first to show initializing state
      setPickerUri(null);
      setStatus("initializing");

      // Create a new session
      const { sessionId: newSessionId, pickerUri: newPickerUri } =
        await createPickerSession();

      // Reset all the states
      setHasStartedSelection(false);
      setExceededLimit(false);
      setSelectionError(null);

      // Set the new session info
      setSessionId(newSessionId);
      setPickerUri(newPickerUri);
      setStatus("selecting");
    } catch (error) {
      console.error("Failed to create new session:", error);
      onError?.("Failed to restart photo selection. Please try again.");
      onClose();
    }
  };

  const handleSelectionStart = () => {
    setHasStartedSelection(true);
    setStatus("waiting");

    if (!pickerUri) return;

    // Store session state before opening picker
    const sessionState = {
      sessionId,
      startTime: Date.now(),
      isMobile,
      pickerUri,
    };

    try {
      localStorage.setItem("photoPickerState", JSON.stringify(sessionState));
    } catch (error) {
      console.warn("Failed to store picker state:", error);
    }

    if (isMobile) {
      try {
        // Direct navigation in new tab without confirmation dialog
        const newTab = document.createElement("a");
        newTab.href = pickerUri;
        newTab.target = "_blank";
        newTab.rel = "noopener noreferrer";

        // Store current tab info
        localStorage.setItem("originalTab", window.location.href);

        // Start polling before opening new tab
        if (checkSelectionRef.current) {
          checkSelectionRef.current();
        }

        // Simulate a direct click from user interaction
        newTab.click();
      } catch (error) {
        console.error("Error opening picker on mobile:", error);
        // Fallback to window.open if createElement approach fails
        window.open(pickerUri, "_blank");
      }
    } else {
      // Desktop: Try popup first, fall back to new tab if blocked
      // Desktop: Try popup first, fall back to new tab if blocked
      try {
        const popup = openPickerInPopup(pickerUri);

        if (!popup || popup.closed || typeof popup.closed === "undefined") {
          console.log("Popup was blocked, falling back to new tab");
          window.open(pickerUri, "_blank");
        } else {
          // Set up popup state check
          const popupCheckInterval = setInterval(() => {
            if (popup.closed) {
              clearInterval(popupCheckInterval);
              window.focus();
              if (checkSelectionRef.current) {
                checkSelectionRef.current();
              }
            }
          }, 500);

          // Clear interval after timeout (e.g., 30 minutes)
          const popupTimeoutId = setTimeout(() => {
            clearInterval(popupCheckInterval);
          }, 30 * 60 * 1000);

          // Store these for cleanup
          popup.checkIntervalId = popupCheckInterval;
          popup.timeoutId = popupTimeoutId;

          // Store popup reference in session state
          const updatedSessionState = {
            ...sessionState,
            popup: {
              checkIntervalId: popupCheckInterval,
              timeoutId: popupTimeoutId,
            },
          };
          localStorage.setItem(
            "photoPickerState",
            JSON.stringify(updatedSessionState)
          );
        }
      } catch (error) {
        console.error("Error opening picker on desktop:", error);
        // Fallback to new tab
        window.open(pickerUri, "_blank");
      }
    }

    // Start immediate polling regardless of device type
    if (checkSelectionRef.current) {
      checkSelectionRef.current();
    }
  };

  // Modified openPickerInPopup to return the window object

  return !pickerUri ? (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="loading-indicator">
          <i className="fas fa-spinner fa-spin"></i>
          <span>Initializing photo selector...</span>
        </div>
      </div>
    </div>
  ) : (
    <div className="modal-backdrop">
      <div className="modal-content photo-selector">
        <button onClick={onClose} className="close-x">
          ×
        </button>

        {!hasStartedSelection ? (
          // In PhotoSelector.js, replace the picker-container section with:

          <div className="picker-container">
            <h2 className="picker-title">Select Photos from Google</h2>

            {!isMobile && (
              <div className="mode-selector">
                <div
                  className={`mode-option ${
                    pickerMode === "web" ? "active" : ""
                  }`}
                  onClick={() => setPickerMode("web")}
                >
                  <i className="fas fa-desktop mode-icon"></i>
                  <div className="mode-label">Desktop</div>
                </div>
                <div
                  className={`mode-option ${
                    pickerMode === "mobile" ? "active" : ""
                  }`}
                  onClick={() => setPickerMode("mobile")}
                >
                  <i className="fas fa-mobile-alt mode-icon"></i>
                  <div className="mode-label">Mobile</div>
                </div>
              </div>
            )}

            <div className="picker-content">
              {isMobile || pickerMode === "web" ? (
                <div className="web-picker">
                  <button
                    onClick={handleSelectionStart}
                    className="primary-button google-button"
                  >
                    <span className="button-icon google-icon"></span>
                    Get Photos from Google
                  </button>
                  <p className="photo-limit-info">
                    Maximum {PHOTO_LIMIT} photos per selection
                  </p>
                  {isMobile && (
                    <div className="mobile-instructions">
                      <strong>
                        After selecting photos return to this tab to continue.
                      </strong>
                      <br />

                      <div className="tip">
                        <i className="fas fa-search"></i>
                        Tip: Search for "RECEIPTS".
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="mobile-picker">
                  <p className="picker-subtitle">
                    Scan to select photos on your phone
                  </p>
                  <div className="qr-code-wrapper">
                    <QRCodeSVG value={pickerUri} size={200} level="H" />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="status-container">
            {status === "processing" ? (
              <div className="processing-status">
                <i className="fas fa-spinner fa-spin"></i>
                <span>Processing {validPhotoCount} selected photos...</span>
              </div>
            ) : status === "error" ? (
              <div className="error-status">
                <i className="fas fa-exclamation-circle"></i>
                <div className="status-message">
                  <strong>{selectionError}</strong>
                  {exceededLimit && (
                    <div className="exceeded-limit-actions">
                      <button onClick={handleRetry} className="retry-button">
                        Make New Selection
                      </button>
                      <p className="limit-note">
                        Please select {PHOTO_LIMIT} photos or fewer.
                      </p>
                    </div>
                  )}
                  {!exceededLimit && <p>You can try selecting photos again.</p>}
                </div>
              </div>
            ) : (
              <div className="waiting-status">
                <i className="fas fa-sync-alt"></i>
                <div className="status-message">
                  <strong>Waiting for your selection...</strong>
                  <p>
                    You can complete your selection in the Google Photos window.
                    This page will update automatically once you're done.
                  </p>
                  {canManuallyRefresh && (
                    <button
                      onClick={handleManualRefresh}
                      className="refresh-button"
                    >
                      Check Selection Status
                    </button>
                  )}
                  {!canManuallyRefresh && status === "waiting" && (
                    <p className="refresh-timer">
                      Next refresh available in{" "}
                      {Math.ceil(
                        (30000 - (Date.now() - lastPollingTime)) / 1000
                      )}{" "}
                      seconds
                    </p>
                  )}
                </div>
              </div>
            )}

            {status !== "processing" && (
              <button onClick={onClose} className="cancel-button">
                Cancel Selection
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoSelector;
